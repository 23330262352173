import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
    access: string,
    role: string,
    phone: number
  }

const initialState : AuthState = {
    access : "",
    role : "",
    phone : 0
}

export const authSlice = createSlice({
    name : 'auth',
    initialState,
    reducers: {
        setAccess: (state, action: PayloadAction<string>) => {
            state.access = action.payload
        },
        setRole: (state, action: PayloadAction<string>) => {
            state.role = action.payload
        },
        setPhone: (state, action: PayloadAction<number>) => {
            state.phone = action.payload
        },
    }
})

export const { setAccess, setRole, setPhone } = authSlice.actions

export default authSlice.reducer