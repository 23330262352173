import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Doubt {
    id: string;
    submission : {
        test : {
            test_title : string
        },
        user : {
            phone : number
        }
    };
    user : {
        phone : number
    };
    question : string;
    answer : string;
    doubt_status : boolean
}

interface DoubtRowProps {
    doubt: Doubt;
    isEven: boolean;
}

const DoubtRow: React.FC<DoubtRowProps> = ({ doubt, isEven }) => {
    const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
    const navHandler = useNavigate()

    return (
        <div onClick={()=>(navHandler(`/doubt/${doubt.id}`))} className={`grid grid-cols-11 gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto`}>
            <div className="overflow-x-auto col-span-4 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5 ">
                {doubt.submission.test.test_title + " - " + doubt.submission.user.phone || '-'}
            </div>
            <div className="overflow-x-auto col-span-2 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {doubt.user.phone || '-'}
            </div>
            <div className="overflow-x-auto col-span-4 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {doubt.question || '-'}
            </div>
            <div className="overflow-x-auto col-span-1 justify-center items-start p-4 text-base leading-6 border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {doubt.doubt_status ? <img src='/tick.svg' /> : <img src='/redCross.svg' />}
            </div>
        </div>
    );
};

export default DoubtRow;