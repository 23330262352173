import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Loader from '../Loader';

function SpecificPromotion() {
  interface promotionType {
    id: string;
    title: string;
    web_image_url: string;
    mobile_image_url: string;
  }

  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true); // Add loading state
  const [status, setStatus] = useState<boolean>(true);
  const [promotion, setPromotion] = useState<promotionType>({
    id: '',
    title: '',
    web_image_url: '',
    mobile_image_url: '',
  });
  const [updatedPromotion, setUpdatedPromotion] = useState<promotionType>({
    id: '',
    title: '',
    web_image_url: '',
    mobile_image_url: '',
  });
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedMobileFile, setSelectedMobileFile] = useState<File>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | undefined>(
    promotion.mobile_image_url
  );
  const [imageWebSrc, setImageWebSrc] = useState<string | undefined>(
    promotion.web_image_url
  );

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageWebSrc(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setImageWebSrc(promotion.web_image_url);
    }
  }, [selectedFile, promotion.web_image_url]);

  useEffect(() => {
    if (selectedMobileFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(selectedMobileFile);
    } else {
      setImageSrc(promotion.mobile_image_url);
    }
  }, [selectedMobileFile, promotion.mobile_image_url]);

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true); // Set loading to true before making API call
      BaseUrl.get(`admin/promotions/${id}`, config)
        .then((res) => {
          setPromotion(res.data);
          setUpdatedPromotion(res.data);
          setLoading(false); // Set loading to false after data is fetched
        })
        .catch((err) => {
          setLoading(false); // Ensure loading is set to false even on error
          if (err.response?.status === 403) {
            dispatch(setAccess(''));
          }
          if (err.response?.status === 404) {
            setStatus(false);
          }
          console.log(err);
        });
    }
  }, [token, id, navHandler, dispatch]);
  async function updatePromotion() {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let banner = '';
    let mobBanner = '';
    if (selectedFile) {
      const response = await BaseUrl.get(
        `promotions-presigned-url/?object_key=${selectedFile.name}`,
        config
      );
      const presignedUrl = response.data.url;
      const uploadResponse = await axios.put(presignedUrl, selectedFile, {
        headers: {
          'Content-Type': selectedFile.type,
          'x-amz-acl': 'public-read',
        },
      });
      if (uploadResponse?.status === 200) {
        const endIndex = presignedUrl.indexOf('?');
        const extractedString = presignedUrl.substring(61, endIndex);
        const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN;
        banner = baseUrl + extractedString;
      } else {
        alert('File upload failed');
      }
    }
    if (selectedMobileFile) {
      const response = await BaseUrl.get(
        `promotions-presigned-url/?object_key=${selectedMobileFile.name}`,
        config
      );
      const presignedUrl = response.data.url;
      const uploadResponse = await axios.put(presignedUrl, selectedMobileFile, {
        headers: {
          'Content-Type': selectedMobileFile.type,
          'x-amz-acl': 'public-read',
        },
      });
      if (uploadResponse?.status === 200) {
        const endIndex = presignedUrl.indexOf('?');
        const extractedString = presignedUrl.substring(61, endIndex);
        const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN;
        mobBanner = baseUrl + extractedString;
      } else {
        alert('File upload failed');
      }
    }
    BaseUrl.put(
      `admin/promotions/${id}/`,
      {
        web_image_url: banner || updatedPromotion.web_image_url,
        mobile_image_url: mobBanner || updatedPromotion.mobile_image_url,
        title: updatedPromotion.title,
      },
      config
    )
      .then((res) => {
        setPromotion(res.data);
        setUpdatedPromotion(res.data);
        setSelectedFile(undefined);
        toast.success('Updated Succesfully');
      })
      .catch((err) => {
        if (err.response?.status == 403) dispatch(setAccess(''));
        console.log(err);
      });
    setIsUpdating(false);
  }

  function deletePromotion() {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    BaseUrl.delete(`admin/promotions/${id}/`, config)
      .then((res) => {
        toast.success('Deleted Succesfully');
        navHandler('/promotions');
      })
      .catch((err) => {
        if (err.response?.status == 403) dispatch(setAccess(''));
        console.log(err);
      });
    setIsModalOpen(false);
  }

  const handleAttachClick = () => {
    const inpElement = document.getElementById('banner');
    inpElement?.click();
  };

  const handleMobAttachClick = () => {
    const inpElement = document.getElementById('mobBanner');
    inpElement?.click();
  };

  const handleFileChange = (event: any) => {
    // Handle the file selection
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleMobileFileChange = (event: any) => {
    // Handle the file selection
    const file = event.target.files[0];
    setSelectedMobileFile(file);
  };

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Promotions" />
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-gray-800 mb-6">
              Are you sure you want to delete this promotion?
            </h2>
            <div className="flex justify-end gap-4">
              <button
                onClick={deletePromotion}
                className="px-6 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 transition duration-200"
              >
                Yes
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-6 py-2 bg-gray-300 text-gray-800 font-semibold rounded-md hover:bg-gray-400 transition duration-200"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="col-span-1 md:col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-5 md:px-11 py-6 w-full border-b border-solid border-zinc-200 flex-wrap">
          <div className="flex flex-col sm:flex-row gap-5 mx-auto sm:mx-0 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center sm:justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Promotion
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">Promotion</p>
              <span>›</span>
              <span
                onClick={() => navHandler('/promotions')}
                className="text-blue-600 cursor-pointer"
              >
                Promotions
              </span>
              <span>›</span>
              {status ? (
                <span>{promotion?.title}</span>
              ) : (
                <span>Not Found</span>
              )}
            </nav>
          </div>
        </header>

        {loading ? (
          <Loader />
        ) : status ? (
          <main className="grid grid-cols-1 md:grid-cols-4 p-5 md:p-8 md:flex flex-col w-full">
            <div className="col-span-1 md:col-span-3 flex flex-col gap-8 p-4 md:p-8 mt-4 bg-white rounded-xl shadow-xl">
              {/* Web Banner Section */}
              <div className="flex flex-col gap-2">
                <label className="font-extrabold">Web Banner:</label>
                <img
                  src={imageWebSrc || promotion.web_image_url}
                  alt={promotion.title}
                  className="py-2 w-full h-auto rounded-lg"
                />
                <div className="flex items-center">
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    id="banner"
                    onChange={handleFileChange}
                  />
                  <div className="flex justify-center items-center w-full">
                    <button
                      onClick={handleAttachClick} // Trigger file input click
                      className="p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg"
                    >
                      {updatedPromotion.web_image_url || selectedFile
                        ? 'Replace'
                        : 'Browse'}
                    </button>
                    <p className="ml-4">
                      {selectedFile?.name ||
                        updatedPromotion.web_image_url?.substring(72)}
                    </p>
                  </div>
                </div>
              </div>

              {/* Mobile Banner Section */}
              <div className="flex flex-col gap-2">
                <label className="font-extrabold">Mobile Banner:</label>
                <img
                  src={imageSrc || promotion.mobile_image_url}
                  alt={promotion.title}
                  className="py-2 w-full max-w-md mx-auto h-auto rounded-lg"
                />
                <div className="flex items-center">
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    id="mobBanner"
                    onChange={handleMobileFileChange}
                  />
                  <div className="flex justify-center items-center w-full">
                    <button
                      onClick={handleMobAttachClick} // Trigger file input click
                      className="p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg"
                    >
                      {updatedPromotion.mobile_image_url || selectedMobileFile
                        ? 'Replace'
                        : 'Browse'}
                    </button>
                    <p className="ml-4">
                      {selectedMobileFile?.name ||
                        updatedPromotion.mobile_image_url?.substring(72)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Action Buttons Section */}
            <div className="my-4 flex flex-col gap-4">
              {isUpdating ? (
                <button className="p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white opacity-50 w-1/6 mx-auto">
                  Updating
                </button>
              ) : (
                (JSON.stringify(updatedPromotion) !==
                  JSON.stringify(promotion) ||
                  selectedFile ||
                  selectedMobileFile) && (
                  <button
                    onClick={() => {
                      setIsUpdating(true);
                      updatePromotion();
                    }}
                    className="p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white w-1/6 mx-auto"
                  >
                    Update
                  </button>
                )
              )}
              <button
                onClick={() => setIsModalOpen(true)}
                className="p-2 rounded-lg bg-red-600 text-lg font-semibold text-white w-1/6 mx-auto"
              >
                Delete
              </button>
            </div>
          </main>
        ) : (
          <div className="p-16 text-3xl font-extrabold text-center">
            No Promotion Found With Id : {id}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default SpecificPromotion;
