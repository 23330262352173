import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Promotion {
    id: string;
    title: string;
}

interface PromotionRowProps {
    promotion: Promotion;
    isEven: boolean;
}

const PromotionRow: React.FC<PromotionRowProps> = ({ promotion, isEven }) => {
    const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
    const navHandler = useNavigate()

    return (
        <div onClick={()=>(navHandler(`/promotion/${promotion.id}`))} className={`grid grid-cols-10 gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto`}>
            <div className="justify-center items-start p-4 text-base font-bold leading-6 text-blue-600 whitespace-nowrap border-t border-solid border-zinc-200 max-md:pr-5">
                {promotion.title}
            </div>
        </div>
    );
};

export default PromotionRow;