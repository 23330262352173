import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SpecificTransaction() {

    interface transactionType {
        payment_id: string,
        razorpay_order_id: string,
        order_value: string,
        payment_status: string
    }
    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const { id } = useParams();
    const [status, setStatus] = useState<boolean>(true)
    const [transaction, setTransaction] = useState<transactionType>({
        payment_id: "",
        razorpay_order_id: "",
        order_value: "",
        payment_status: ""
    })

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/transaction/${id}`, config)
                .then((res) => {
                    setTransaction(res.data)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    if (err.response.status == 404)
                        setStatus(false)
                    console.log(err)
                })
        }
    }, [])

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Transactions' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Transactions
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <a href="/users" className="text-blue-600">Transactions</a>
                            <span>›</span>
                            <span className='text-blue-600 cursor-pointer' onClick={() => navHandler("/transactions")}>Transactions</span>
                            <span>›</span>
                            {status ? <span>{transaction.payment_id}</span> : <span>Not Found</span>}
                        </nav>
                    </div>
                </header>
                {status ? <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-1/2 mx-auto">
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Payment id : </span>
                            <p className='col-span-2 py-2 px-8'>{transaction.payment_id || '-'}</p>
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Razorpay id : </span>
                            <p className='col-span-2 py-2 px-8'>{transaction.razorpay_order_id || '-'}</p>
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Payment Status : </span>
                            <p className='col-span-2 py-2 px-8'>{transaction.payment_status || '-'}</p>
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Order Value : </span>
                            <p className='col-span-2 py-2 px-8'>{transaction.order_value || '-'}</p>
                        </pre>
                        {/* {(transaction.phone != phone || transaction.email != email) && <button className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white'>Update</button>} */}
                    </div>
                </main> : <div className='p-16 text-3xl font-extrabold'>No User Found With Id : {id}</div>}
            </div>
            <ToastContainer />
        </div>
    );
}

export default SpecificTransaction;
