import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OrdersState {
    count: number;
    previous: string | null;
    next: string | null;
    current: number;
    results: any[]; // Update with appropriate type for results array
}

const initialState: OrdersState = {
    count: 0,
    previous: null,
    next: null,
    results: [],
    current: 1
};

export const ordersSlice = createSlice({
    name: 'orders', 
    initialState,
    reducers: {
        setOrders: (state, action: PayloadAction<OrdersState>) => {
            state.count = action.payload.count;
            state.previous = action.payload.previous;
            state.next = action.payload.next;
            state.results = action.payload.results;
            state.current = action.payload.current || 1;
        },
        setCurrent: (state, action: PayloadAction<number>) => {
            state.current = action.payload;
        },
    },
});

export const { setOrders, setCurrent } = ordersSlice.actions;

export default ordersSlice.reducer;