import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SpecificTestSeries() {

    interface attemptInterface {
        id: number,
        month?: string,
        year?: string
    }

    interface courseTypeInterface {
        id?: number,
        title?: string,
        attempts?: Array<attemptInterface>,
    }

    interface testSeriesTypeInterface {
        id?: number,
        title?: string,
    }

    interface testSeriesInterface {
        discounted_price: number,
        mrp_price: number,
        title: string,
        description: string,
        course_type: courseTypeInterface,
        status: boolean,
        test_series_type: testSeriesTypeInterface,
        test_series_attempts: Array<attemptInterface>,
        valid_from: string,
        valid_till: string,
    }

    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const { id } = useParams();
    const [status, setStatus] = useState<boolean>(true)
    const [testSeries, setTestSeries] = useState<testSeriesInterface>({
        discounted_price: 0,
        mrp_price: 0,
        title: "",
        description: "",
        course_type: {},
        status: false,
        test_series_type: {},
        test_series_attempts: [],
        valid_from: "",
        valid_till: "",
    })
    const [testSeriesNew, setTestSeriesNew] = useState<testSeriesInterface>({
        discounted_price: 0,
        mrp_price: 0,
        title: "",
        description: "",
        course_type: {},
        status: false,
        test_series_type: {},
        test_series_attempts: [],
        valid_from: "",
        valid_till: "",
    })
    const [courseType, setCourseType] = useState<courseTypeInterface[]>([])
    const [courseTypeId, setCourseTypeId] = useState<number>()
    const [testSeriesType, setTestSeriesType] = useState<courseTypeInterface[]>([])
    const [testSeriesTypeId, setTestSeriesTypeId] = useState<number>()
    const [isListVisible, setIsListVisible] = useState(false);
    const [orgAttempts, setOrgAttempts] = useState<number[]>([]);
    const [selectedAttempts, setSelectedAttempts] = useState<number[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleListVisibility = () => {
        setIsListVisible(!isListVisible);
    };

    const handleCheckboxChange = (event: { target: { checked: any; }; }, attemptId: number) => {
        if (event.target.checked) {
            setSelectedAttempts([...selectedAttempts, attemptId]);
        } else {
            setSelectedAttempts(selectedAttempts.filter(id => id !== attemptId));
        }
    };

    const removeSelectedAttempt = (attemptId: number) => {
        setSelectedAttempts(selectedAttempts.filter(id => id !== attemptId));
    };

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/test-series/${id}`, config)
                .then((res) => {
                    setTestSeries(res.data)
                    setTestSeriesNew(res.data)
                    console.log(res.data)
                    setCourseTypeId(res.data.course_type?.id)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    if (err.response.status == 404)
                        setStatus(false)
                    console.log(err)
                })
            BaseUrl.get(`admin/non-paginated-course-type/`, config)
                .then((res) => {
                    setCourseType(res.data)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    if (err.response.status == 404)
                        setStatus(false)
                    console.log(err)
                })
            BaseUrl.get(`admin/non-paginated-test-series-type/`, config)
                .then((res) => {
                    setTestSeriesType(res.data)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    if (err.response.status == 404)
                        setStatus(false)
                    console.log(err)
                })
        }
    }, [])

    function updateTestSeries() {
        const config = {
            headers: {
                Authorization: `${token}`,
                'Content-Type': 'application/json',
            },
        };
        BaseUrl.patch(`admin/test-series/${id}/`, {
            course_type_id: courseTypeId,
            test_series_type_id: testSeriesTypeId,
            title: testSeriesNew.title,
            valid_from: testSeriesNew.valid_from,
            valid_till: testSeriesNew.valid_till,
            description: testSeriesNew.description,
            discounted_price: testSeriesNew.discounted_price,
            mrp_price: testSeriesNew.mrp_price,
            status: testSeriesNew.status,
            test_series_attempts_ids: selectedAttempts
        }, config)
            .then((res) => {
                setTestSeries(res.data)
                setTestSeriesNew(res.data)
                console.log(res.data.test_series_type.id)
                console.log(testSeriesNew.course_type.id)
                // console.log(res.data)
                toast.success("Updated Succesfully")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
    }

    function deleteTestSeries() {
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        BaseUrl.delete(`admin/test-series/${id}/`, config)
            .then((res) => {
                toast.success("Deleted Succesfully")
                navHandler("/test-series")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
        setIsModalOpen(false)
    }

    useEffect(() => {
        const attemptsIds = testSeries.test_series_attempts.map(test => test?.id);
        setSelectedAttempts(attemptsIds);
        setOrgAttempts(attemptsIds);
    }, [testSeries.test_series_attempts]);

    const formatDateForInput = (dateString: string) => {
        if (!dateString) {
            return '';  // Return an empty string if the date is null or undefined
        }
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const convertToBackendFormat = (localDateTime: string) => {
        const date = new Date(localDateTime);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;  // Return only the date part
    };    

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Test series' />
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 max-w-md mx-auto">
                        <h2 className="text-xl font-semibold text-gray-800 mb-6">
                            Are you sure you want to delete this Test Series?
                        </h2>
                        <div className="flex justify-end gap-4">
                            <button
                                onClick={deleteTestSeries}
                                className="px-6 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 transition duration-200"
                            >
                                Yes
                            </button>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="px-6 py-2 bg-gray-300 text-gray-800 font-semibold rounded-md hover:bg-gray-400 transition duration-200"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Test Series
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <p className="text-blue-600">Testseries</p>
                            <span>›</span>
                            <span className="text-blue-600 cursor-pointer" onClick={() => navHandler("/test-series")}>Test series - </span>
                            {status ? <span>{testSeries.title}</span> : <span>Not Found</span>}
                        </nav>
                    </div>
                </header>
                {status ? <main className="grid grid-cols-4 flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="col-span-3 flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-11/12 mx-auto">
                        <pre className='w-full grid grid-cols-4 items-center'>
                            <span className='font-extrabold'>Title : </span>
                            <input onChange={(e) => setTestSeriesNew(prev => ({ ...prev, title: e.target.value }))} className='col-span-3 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={testSeriesNew.title || '-'} />
                        </pre>
                        <pre className='w-full grid grid-cols-4'>
                            <span className='font-extrabold'>Description : </span>
                            <textarea onChange={(e) => setTestSeriesNew(prev => ({ ...prev, description: e.target.value }))} className='h-[15vh] col-span-3 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={testSeriesNew.description || '-'} />
                        </pre>
                        {testSeriesType && <pre className='w-full grid grid-cols-4 items-center'>
                            <span className='font-extrabold'>Test Series type : </span>
                            <select onChange={(e) => {
                                const selectedTestSeriesTypeId = setTestSeriesTypeId(parseInt(e.target.value));
                                const selectedTestSeriesType = Object.values(testSeriesType).find(testSeriesType => testSeriesType?.id == selectedTestSeriesTypeId);
                                setTestSeriesNew(prev => ({ ...prev, test_series_type: selectedTestSeriesType || {} }))
                            }} className='col-span-3 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {Object.entries(testSeriesType).map(([key, testSeriesType]) => (
                                    testSeriesType && testSeriesType?.id && (
                                        <option className='w-full' key={key} value={testSeriesType?.id} selected={testSeriesType?.id == testSeriesNew.test_series_type.id}>
                                            {testSeriesType?.id} - {testSeriesType.title}
                                        </option>
                                    )
                                ))}
                            </select>
                        </pre>}
                        {courseType && <pre className='w-full grid grid-cols-4 items-center'>
                            <span className='font-extrabold'>Course type : </span>
                            <select onChange={(e) => {
                                setCourseTypeId(parseInt(e.target.value));
                                const selectedCourseId = parseInt(e.target.value)
                                const selectedCourse = courseType.find(course => course?.id == selectedCourseId);
                                setTestSeriesNew(prev => ({ ...prev, course_type: selectedCourse || {} }))
                            }} className='col-span-3 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {Object.entries(courseType).map(([key, course]) => (
                                    course && course?.id && (
                                        <option className='w-full' key={key} value={course?.id} selected={course?.id == testSeries.course_type?.id}>
                                            {course?.id} - {course.title}
                                        </option>
                                    )
                                ))}
                            </select>
                        </pre>}
                        <pre className='w-full grid grid-cols-4'>
                            <span className='font-extrabold'>Attempts : </span>
                            <div className='grid grid-cols-2 gap-2 w-fit col-span-3 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {testSeriesNew.course_type?.attempts && testSeriesNew.course_type?.attempts
                                    .filter(attempt => selectedAttempts.includes(attempt?.id))
                                    .map((attempt) => (
                                        <div key={attempt?.id} className='mb-2 flex text-xs bg-gray-200 w-fit p-2 border border-1 border-gray-400 rounded-lg'>
                                            <div
                                                className='pr-2 border-r-2 border-gray-400 mr-2 cursor-pointer'
                                                onClick={() => removeSelectedAttempt(attempt?.id)}
                                            >
                                                x
                                            </div>
                                            {attempt?.id} - {attempt.month}, {attempt.year}
                                        </div>
                                    ))}
                            </div>
                            <div className='grid grid-cols-12 my-2 p-2 col-start-2 col-span-2'>
                                {!isListVisible ? <img onClick={toggleListVisibility} className='cursor-pointer' src='/add.svg' alt='add' /> : <img onClick={toggleListVisibility} className='cursor-pointer w-1/2' src='/cross.svg' alt='add' />}
                                {isListVisible && (
                                    <div className='col-span-11 mx-auto p-4 border border-2 border-gray-500 rounded-lg'>
                                        {testSeriesNew.course_type?.attempts && testSeriesNew.course_type?.attempts.map(attempt => (
                                            <div key={attempt?.id}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        value={attempt?.id}
                                                        onChange={(e) => handleCheckboxChange(e, attempt?.id)}
                                                        className='mr-4'
                                                        checked={selectedAttempts.includes(attempt?.id)}
                                                    />
                                                    {attempt?.id} - {attempt.month}, {attempt.year}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </pre>
                        <div className='w-full items-center'>
                            <pre className='w-full grid grid-cols-3 items-center'>
                                <span className='font-extrabold'>Valid from : </span>
                                <input type="datetime-local" onChange={(e) => setTestSeriesNew(prev => ({ ...prev, valid_from: convertToBackendFormat(e.target.value) }))} className='p-2 outline-none border border-1 border-zinc-300 rounded-lg' value={formatDateForInput(testSeriesNew.valid_from)} />
                            </pre>
                        </div>
                        <div className='w-full items-center'>
                            <pre className='w-full grid grid-cols-3 items-center'>
                                <span className='font-extrabold'>Valid Till : </span>
                                <input type="datetime-local" onChange={(e) => setTestSeriesNew(prev => ({ ...prev, valid_till: convertToBackendFormat(e.target.value) }))} className='p-2 outline-none border border-1 border-zinc-300 rounded-lg' value={formatDateForInput(testSeriesNew.valid_till)} />
                            </pre>
                        </div>
                        <pre className='w-full grid grid-cols-4 items-center'>
                            <span className='font-extrabold'>Status : </span>
                            <input type='checkbox' onChange={(e) => setTestSeriesNew(prev => ({ ...prev, status: e.target.checked }))} className='outline-none border border-1 border-zinc-300 rounded-lg' checked={testSeriesNew.status || false} />
                        </pre>
                        <pre className='w-full grid grid-cols-4 items-center'>
                            <span className='font-extrabold'>Discounted price : </span>
                            <input onChange={(e) => setTestSeriesNew(prev => ({ ...prev, discounted_price: parseFloat(e.target.value) }))} className='w-1/2 col-span-3 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={testSeriesNew.discounted_price} />
                        </pre>
                        <pre className='w-full grid grid-cols-4 items-center'>
                            <span className='font-extrabold'>Mrp price : </span>
                            <input onChange={(e) => setTestSeriesNew(prev => ({ ...prev, mrp_price: parseFloat(e.target.value) }))} className='w-1/2 col-span-3 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={testSeriesNew.mrp_price} />
                        </pre>
                    </div>
                    <div className='my-4'>
                        {((JSON.stringify(testSeriesNew) != JSON.stringify(testSeries)) || (selectedAttempts != orgAttempts)) && <button onClick={updateTestSeries} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white w-5/6'>Update</button>}
                        <button onClick={() => setIsModalOpen(true)} className='p-2 rounded-lg bg-red-600 text-lg font-semibold text-white w-5/6 my-4'>Delete</button>
                    </div>
                </main> : <div className='p-16 text-3xl font-extrabold'>No User Found With Id : {id}</div>}
            </div>
            <ToastContainer />
        </div>
    );
}

export default SpecificTestSeries;
