import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import UserRow from './PromotionRow';


const PromotionsTable: React.FC = () => {
    const promotions = useAppSelector((state) => state.promotion.results) || []
    return (
        <section>
            <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
                <div className="text-left w-full text-base font-bold leading-6 text-blue-600">
                    <p className='p-4'>Promotions</p>
                </div>
                {promotions.map((promotion, index) => (
                    <UserRow key={index} promotion={promotion} isEven={index % 2 === 0} />
                ))}
            </div>
        </section>
    );
};

export default PromotionsTable;