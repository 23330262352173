import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import SubmissionsTable from './SubmissionsTable';
import { setCurrentSubmissionPage, setSubmissions } from '../../redux/slices/submissions';

function Submissions() {
  interface TestTypes {
    id: string,
    title: string
  }
  const navHandler = useNavigate()
  const token = useAppSelector((state: RootState) => state.auth.access)
  const prev = useAppSelector((state: RootState) => state.submission.previous)
  const next = useAppSelector((state: RootState) => state.submission.next)
  const current = useAppSelector((state: RootState) => state.submission.current)
  const dispatch = useAppDispatch()
  const [testSeries, setTestSeries] = useState('');
  const [testSeriesList, setTestSeriesList] = useState<TestTypes[]>([]);
  const [number, setNumber] = useState('');
  const [debouncedNumber, setDebouncedNumber] = useState(number);
  const [testSeriesType, setTestSeriesType] = useState('');
  const [testSeriesTypes, setTestSeriesTypes] = useState<TestTypes[]>([]);
  const [courseType, setCourseType] = useState('');
  const [courseTypes, setCourseTypes] = useState<TestTypes[]>([]);
  const [subject, setSubject] = useState('');
  const [subjects, setSubjects] = useState<TestTypes[]>([]);
  const [checkStatus, setCheckStatus] = useState('');
  const [assigned, setAssigned] = useState(true)
  const [teacherChange, setTeacherChange] = useState(false)

  useEffect(() => {
    if (!token) {
      navHandler("/login")
    }
    else {
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };
      BaseUrl.get(`admin/non-paginated-test-series`, config)
        .then((res) => {
          setTestSeriesList(res.data)
        })
        .catch((err) => {
          if (err.response.status == 403)
            dispatch(setAccess(""))
          console.log(err)
        })
      BaseUrl.get(`admin/non-paginated-test-series-type`, config)
        .then((res) => {
          setTestSeriesTypes(res.data)
        })
        .catch((err) => {
          if (err.response.status == 403)
            dispatch(setAccess(""))
          console.log(err)
        })
      BaseUrl.get(`admin/non-paginated-course-type/`, config)
        .then((res) => {
          setCourseTypes(res.data)
        })
        .catch((err) => {
          if (err.response.status == 403)
            dispatch(setAccess(""))
          console.log(err)
        })
      BaseUrl.get(`admin/non-paginated-subject`, config)
        .then((res) => {
          setSubjects(res.data)
        })
        .catch((err) => {
          if (err.response.status == 403)
            dispatch(setAccess(""))
          console.log(err)
        })
    }
  }, [])
  useEffect(() => {
    if (!token) {
      navHandler("/login")
    }
    else {
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };
      BaseUrl.get(`filter-submission/?page=${current}&test_series_type=${testSeriesType}&course_type=${courseType}&user_phone=${number}&test_series=${testSeries}${checkStatus && `&check_status=${checkStatus}`}&subject=${subject}`, config)
        .then((res) => {
          const curr = res.data.current
          const modifiedData = {
            ...(res.data),
            current: curr
          };
          dispatch(setSubmissions(modifiedData))
        })
        .catch((err) => {
          if (err.response.status == 403)
            dispatch(setAccess(""))
          console.log(err)
        })
    }
  }, [current, courseType, testSeriesType, debouncedNumber, testSeries, checkStatus, subject, teacherChange])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedNumber(number);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [number]);

  return (
    <div className="text-sm grid grid-cols-5">
      <Sidebar activeLabel='Submissions' />
      <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Submissions
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">Home</a>
              <span>›</span>
              <p className="text-blue-600">Reports</p>
              <span>›</span>
              <span>Submissions</span>
            </nav>
          </div>
        </header>
        <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="my-6">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-700">Filters</p>
              <button
                onClick={() => {
                  setTestSeriesType('');
                  setCourseType('');
                  setNumber('');
                  setTestSeries('');
                  setSubject('');
                  setCheckStatus('')
                }}
                className="px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Clear All Filters
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <label htmlFor="number" className="block text-sm font-medium text-gray-700 mb-2">User:</label>
                <input
                  type="number"
                  id="number"
                  name="number"
                  placeholder="Enter User Number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label htmlFor="testSeriesType" className="block text-sm font-medium text-gray-700 mb-2">Test Series Type:</label>
                <select
                  id="testSeriesType"
                  name="testSeriesType"
                  value={testSeriesType}
                  onChange={(e) => setTestSeriesType(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">----</option>
                  {testSeriesTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="testSeries" className="block text-sm font-medium text-gray-700 mb-2">Test Series:</label>
                <select
                  id="testSeries"
                  name="testSeries"
                  value={testSeries}
                  onChange={(e) => setTestSeries(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">----</option>
                  {testSeriesList.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="courseType" className="block text-sm font-medium text-gray-700 mb-2">Course Type:</label>
                <select
                  id="courseType"
                  name="courseType"
                  value={courseType}
                  onChange={(e) => setCourseType(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">----</option>
                  {courseTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="subjects" className="block text-sm font-medium text-gray-700 mb-2">Subject:</label>
                <select
                  id="subjects"
                  name="subjects"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">----</option>
                  {subjects.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="checkStatus" className="block text-sm font-medium text-gray-700 mb-2">Check Status:</label>
                <select
                  id="checkStatus"
                  name="checkStatus"
                  value={checkStatus}
                  onChange={(e) => setCheckStatus(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">----</option>
                  <option value="False">False</option>
                  <option value="True">True</option>
                </select>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-3 text-xs my-4 w-1/2 gap-4 font-extrabold'>
            {assigned ? <p className='p-2 rounded-full border border-1 border-blue-600 bg-blue-200 text-blue-700 text-center cursor-pointer'>Teacher Assigned</p> : <p onClick={() => setAssigned(true)} className='p-2 rounded-full border border-1 border-black text-center cursor-pointer'>Teacher Assigned</p>}
            {!assigned ? <p className='p-2 rounded-full border border-1 border-blue-600 bg-blue-200 text-blue-700 text-center cursor-pointer'>Teacher Not Assigned</p> : <p onClick={() => setAssigned(false)} className='p-2 rounded-full border border-1 border-black text-center cursor-pointer'>Teacher Not Assigned</p>}
          </div>
          <SubmissionsTable teacherChange={teacherChange} setTeacherChange={setTeacherChange} assigned={assigned} />
        </main>
        <div className='flex gap-2 w-full justify-center items-center m-4'>
          {prev && <button onClick={() => dispatch(setCurrentSubmissionPage(current - 1))} className='px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer'>{"<"}</button>}
          <div className='px-2 py-1 rounded-lg border border-2 border-blue-400 text-xl text-white font-semibold bg-blue-600 cursor-pointer'>{current}</div>
          {next && <button onClick={() => dispatch(setCurrentSubmissionPage(current + 1))} className='px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer'>{">"}</button>}
        </div>
      </div>
    </div>
  );
}

export default Submissions;
