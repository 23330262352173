import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import CouponsTable from './CouponsTable';
import { setCurrentCouponPage, setCoupons } from '../../redux/slices/coupons';

function Coupons() {
  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const prev = useAppSelector((state: RootState) => state.coupon.previous);
  const next = useAppSelector((state: RootState) => state.coupon.next);
  const current = useAppSelector((state: RootState) => state.coupon.current);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false); // State to manage loading

  useEffect(() => {
    if (!token) {
      navHandler("/login");
    } else {
      setIsLoading(true); // Set loading to true before fetching data
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };
      BaseUrl.get(`admin/coupons/?page=${current}`, config)
        .then((res) => {
          const curr = res.data.current;
          const modifiedData = {
            ...(res.data),
            current: curr,
          };
          console.log(modifiedData);
          dispatch(setCoupons(modifiedData));
        })
        .catch((err) => {
          if (err.response.status === 403) {
            dispatch(setAccess(""));
          }
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false after data is fetched
        });
    }
  }, [current]);

  return (
    <div className="text-sm grid grid-cols-5">
      <Sidebar activeLabel="Coupons" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Coupon
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">Home</a>
              <span>›</span>
              <p className="text-blue-600">Coupon</p>
              <span>›</span>
              <span>Coupons</span>
            </nav>
          </div>
          <button onClick={() => navHandler("create")} className="flex gap-2.5 items-center justify-center p-4 text-base text-center text-white bg-green-600 rounded">
            <img src='/addOrder.svg' />
            <span className="leading-[150%]">Add Coupon</span>
          </button>
        </header>
        <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <CouponsTable />
        </main>
        <div className="flex gap-2 w-full justify-center items-center m-4">
          {prev && (
            <button
              onClick={() => dispatch(setCurrentCouponPage(current - 1))}
              className={`px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isLoading} // Disable the button when loading
            >
              {"<"}
            </button>
          )}
          <div className="px-2 py-1 rounded-lg border border-2 border-blue-400 text-xl text-white font-semibold bg-blue-600 cursor-pointer">
            {current}
          </div>
          {next && (
            <button
              onClick={() => dispatch(setCurrentCouponPage(current + 1))}
              className={`px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isLoading} // Disable the button when loading
            >
              {">"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Coupons;