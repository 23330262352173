import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setAccess } from '../../redux/slices/auth';

function ResetPassword() {
    const navHandler = useNavigate();
    const token = useAppSelector((state: RootState) => state.auth.access);
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        if (!token) {
            navHandler("/login");
        }
    }, [token, navHandler]);

    function updatePassword() {
        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        BaseUrl.patch(`admin/employee/${id}/`, {
            password: password,
        }, config)
            .then((res) => {
                toast.success("Password reset successfully");
            })
            .catch((err) => {
                if (err.response.status === 403) {
                    dispatch(setAccess(""));
                }
                console.log(err);
            });
    }

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Employees' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Employees
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <p className="text-blue-600">Employees</p>
                            <span>›</span>
                            <span>Employees</span>
                        </nav>
                    </div>
                </header>
                <main className="grid grid-cols-2 flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="border border-gray-300 p-2 rounded"
                        />
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className='p-8'>
                        <button
                            onClick={updatePassword}
                            className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
                        >
                            Reset Password
                        </button>
                    </div>
                </main>
            </div>
            <ToastContainer />
        </div>
    );
}

export default ResetPassword;