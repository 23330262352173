import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const navHandler = useNavigate()
  useEffect(()=>{
    navHandler("/orders")
  },[])
  return (
    <div className="text-sm grid grid-cols-5"></div>)
}

export default Dashboard;
