import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Orders from './components/orders/Orders';
import Login from './components/auth/Login';
import SpecificOrder from './components/orders/SpecificOrder';
import Users from './components/users/Users';
import SpecificUser from './components/users/SpecificUser';
import Transactions from './components/transactions/Transactions';
import SpecificTransaction from './components/transactions/SpecificTransaction';
import PaymentInformation from './components/payment/PaymentInformation';
import TestTypes from './components/test-types/TestTypes';
import SpecificTestType from './components/test-types/SpecificTestType';
import TestSeries from './components/test-series/TestSeries';
import SpecificTestSeries from './components/test-series/SpecificTestSeries';
import Tests from './components/tests/Test';
import SpecificTest from './components/tests/SpecificTest';
import CreateTestSeries from './components/test-series/CreateTestSeries';
import CreateTest from './components/tests/CreateTest';
import Employees from './components/employees/Employee';
import SpecificEmployee from './components/employees/SpecificEmployee';
import CreateEmployee from './components/employees/CreateEmployee';
import Doubts from './components/doubts/Doubts';
import SpecificDoubt from './components/doubts/SpecificDoubt';
import Submissions from './components/submissions/Submissions';
import SpecificSubmission from './components/submissions/SpecificSubmission';
import Dashboard from './components/dashboard/dashboard';
import Promotions from './components/promotions/Promotions';
import SpecificPromotion from './components/promotions/SpecificPromotion';
import Coupons from './components/coupons/Coupons';
import SpecificCoupon from './components/coupons/SpecificCoupon';
import Fees from './components/fees/Fees';
import SpecificFees from './components/fees/SpecificFees';
import AddPromotion from './components/promotions/AddPromotion';
import AddCoupon from './components/coupons/AddCoupon';
import ResetPassword from './components/employees/resetPassword';
import AddFees from './components/fees/AddFees';
import CreateTestType from './components/test-types/CreateTestType';

function App() {
  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/order/:id" element={<SpecificOrder />} />
      <Route path="/users" element={<Users />} />
      <Route path="/user/:id" element={<SpecificUser />} />
      <Route path="/transactions" element={<Transactions />} />
      <Route path="/transaction/:id" element={<SpecificTransaction />} />
      <Route path="/payment-information" element={<PaymentInformation />} />
      <Route path="/test-series-types" element={<TestTypes />} />
      <Route path="/test-series-types/create" element={<CreateTestType />} />
      <Route path="/test-series-type/:id" element={<SpecificTestType />} />
      <Route path="/test-series" element={<TestSeries />} />
      <Route path="/test-series/:id" element={<SpecificTestSeries />} />
      <Route path="/test-series/create" element={<CreateTestSeries />} />
      <Route path="/tests" element={<Tests />} />
      <Route path="/tests/:id" element={<SpecificTest />} />
      <Route path="/tests/create" element={<CreateTest />} />
      <Route path="/employees" element={<Employees />} />
      <Route path="/employee/:id" element={<SpecificEmployee />} />
      <Route path="/employee/reset/:id" element={<ResetPassword />} />
      <Route path="/employees/create" element={<CreateEmployee />} />
      <Route path="/doubts" element={<Doubts />} />
      <Route path="/doubt/:id" element={<SpecificDoubt />} />
      <Route path="/submissions" element={<Submissions />} />
      <Route path="/submission/:id" element={<SpecificSubmission />} />
      <Route path="/promotions" element={<Promotions />} />
      <Route path="/promotions/create" element={<AddPromotion />} />
      <Route path="/promotion/:id" element={<SpecificPromotion />} />
      <Route path="/coupons" element={<Coupons />} />
      <Route path="/coupons/:id" element={<SpecificCoupon />} />
      <Route path="/coupons/create" element={<AddCoupon />} />
      <Route path="/fees-and-syllabus" element={<Fees />} />
      <Route path="/fees-and-syllabus/:id" element={<SpecificFees />} />
      <Route path="/fees-and-syllabus/create" element={<AddFees />} />
    </Routes>
  </BrowserRouter>;
}

export default App;
