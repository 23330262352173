import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function CreateTest() {

    interface courseTypeInterface {
        id?: number,
        title?: string,
        subjects?: Array<SubjectInterface>,
    }

    interface testSeriesInterface {
        id: number,
        title: string,
        course_type: courseTypeInterface
    }

    interface SubjectInterface {
        id?: number,
        title?: string,
    }

    interface testInterface {
        time_duration: number,
        total_marks: number,
        test_title: string,
        description: string,
        test_series: testSeriesInterface,
        subject: SubjectInterface,
        valid_from: string,
        valid_to: string,
        question_paper: string,
        answer_key: string
    }

    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const [testNew, setTestNew] = useState<testInterface>({
        time_duration: 0,
        total_marks: 0,
        test_title: "",
        description: "",
        test_series: {
            id: 0,
            title: "",
            course_type: {}
        },
        subject : {},
        valid_from: "",
        valid_to: "",
        question_paper: "",
        answer_key: ""
    })
    const [testSeries, setTestSeries] = useState<testSeriesInterface[]>([])
    const [subjects, setSubjects] = useState<SubjectInterface[]>([])
    const [selectedFile, setSelectedFile] = useState<File>()
    const [selectedAnswerKey, setSelectedAnswerKey] = useState<File>()

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/non-paginated-test-series/`, config)
                .then((res) => {
                    setTestSeries(res.data)
                    const selectedTestSeries:testSeriesInterface = res.data[0]
                    setTestNew(prev => ({ ...prev, test_series: selectedTestSeries || {
                        id: 0,
                        title: "",
                        course_type: {}
                    } }))
                    const subjects: SubjectInterface[] = selectedTestSeries?.course_type?.subjects || []
                    setSubjects(subjects)
                    if((!subjects.includes(testNew.subject))){
                        setTestNew(prev => ({ ...prev, subject: subjects[0] || {} }))
                    }
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    console.log(err)
                })
        }
    }, [])

    async function addTest() {
        const config = {
            headers: {
                Authorization: `${token}`,
                'Content-Type': 'application/json',
            },
        };
        if(!testNew.subject.id){
            toast.error("Subject Can Not Be Blank")
            return
        }
        let ansKey = ""
        if (selectedAnswerKey) {
            const response = await BaseUrl.get(`answer-key-presigned-url/?object_key=${selectedAnswerKey.name}`, config)
            const presignedUrl = response.data.url
            const uploadResponse = await axios.put(presignedUrl, selectedAnswerKey, {
                headers: {
                    'Content-Type': selectedAnswerKey.type,
                    'x-amz-acl': 'public-read',
                },
            });
            if (uploadResponse.status === 200) {
                const endIndex = presignedUrl.indexOf('?');
                const extractedString = presignedUrl?.substring(61, endIndex);
                const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN
                ansKey = baseUrl + extractedString
            } else {
                alert("File upload failed");
            }
        }
        let quesPaper = ""
        if (selectedFile) {
            const response = await BaseUrl.get(`question-paper-presigned-url/?object_key=${selectedFile.name}`, config)
            const presignedUrl = response.data.url
            const uploadResponse = await axios.put(presignedUrl, selectedFile, {
                headers: {
                    'Content-Type': selectedFile.type,
                    'x-amz-acl': 'public-read',
                },
            });
            if (uploadResponse.status === 200) {
                const endIndex = presignedUrl.indexOf('?');
                const extractedString = presignedUrl?.substring(61, endIndex);
                const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN
                quesPaper = baseUrl + extractedString
            } else {
                alert("File upload failed");
            }
        }
        BaseUrl.post(`admin/tests/`, {
            test_title: testNew.test_title,
            description: testNew.description,
            time_duration: testNew.time_duration,
            total_marks: testNew.total_marks,
            test_series_id: testNew.test_series.id, 
            subject_id: testNew.subject.id,
            valid_from: testNew.valid_from,
            valid_to: testNew.valid_to,
            question_paper : quesPaper || testNew.question_paper,
            answer_key : ansKey || testNew.answer_key
        }, config)
            .then((res) => {
                toast.success("Created Succesfully")
                navHandler(`/tests/${res.data.id}`)
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
    }

    const formatDateForInput = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const convertToBackendFormat = (localDateTime: string) => {
        const date = new Date(localDateTime);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    };

    const handleButtonClick = () => {
        const inpElement = document.getElementById("questionFile")
        inpElement?.click()
    };

    const handleAnswerButtonClick = () => {
        const inpElement = document.getElementById("answerFile")
        inpElement?.click()
    };

    const handleFileChange = (event: any) => {
        // Handle the file selection
        const file = event.target.files[0];
        setSelectedFile(file)
    };

    const handleAnswerKeyChange = (event: any) => {
        // Handle the file selection
        const file = event.target.files[0];
        setSelectedAnswerKey(file)
    };

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Tests' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Test
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <p className="text-blue-600">Test</p>
                            <span>›</span>
                            <span>Test - </span>
                            <span>Create</span>
                        </nav>
                    </div>
                </header>
                <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-11/12 mx-auto">
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Test Title : </span>
                            <input onChange={(e) => setTestNew(prev => ({ ...prev, test_title: e.target.value }))} className='col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={testNew.test_title} />
                        </pre>
                        {testSeries && <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Test Series : </span>
                            <select onChange={(e) => {
                                const selectedTestSeriesId = parseInt(e.target.value);
                                const selectedTestSeries = testSeries.find(testSeries => testSeries.id == selectedTestSeriesId);
                                setTestNew(prev => ({ ...prev, test_series: selectedTestSeries || {
                                    id: 0,
                                    title: "",
                                    course_type: {}
                                } }))
                                const subjects: SubjectInterface[] = selectedTestSeries?.course_type?.subjects || []
                                setSubjects(subjects)
                                if((!subjects.includes(testNew.subject))){
                                    setTestNew(prev => ({ ...prev, subject: subjects[0] || {} }))
                                }
                            }} className='col-span-2 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {testSeries.map((testSeries, index) => (
                                    testSeries && (
                                        <option className='w-full' key={index} value={testSeries.id} selected={testSeries.id == testNew.test_series.id}>
                                            {testSeries.id} - {testSeries.title}
                                        </option>
                                    )
                                ))}
                            </select>
                        </pre>}
                        {subjects && <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Subject : </span>
                            <select onChange={(e) => {
                                const subject = subjects[parseInt(e.target.value)]
                                const selectedSubject = subjects.find(sub => sub.id == subject.id);
                                setTestNew(prev => ({ ...prev, subject: selectedSubject || {} }))
                            }} className='col-span-2 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {subjects.map((subject, key) => (
                                    subject && subject.id && (
                                        <option className='w-full' key={key} value={key} selected={subject.id == testNew.subject.id}>
                                            {subject.id} - {subject.title}
                                        </option>
                                    )
                                ))}
                            </select>
                        </pre>}
                        <div className='w-full items-center'>
                            <pre className='w-full grid grid-cols-3 items-center'>
                                <span className='font-extrabold'>Valid from : </span>
                                <input type="datetime-local" onChange={(e) => setTestNew(prev => ({ ...prev, valid_from: convertToBackendFormat(e.target.value) }))} className='p-2 outline-none border border-1 border-zinc-300 rounded-lg' value={formatDateForInput(testNew.valid_from)} />
                            </pre>
                        </div>
                        <div className='w-full items-center'>
                            <pre className='w-full grid grid-cols-3 items-center'>
                                <span className='font-extrabold'>Valid from : </span>
                                <input type="datetime-local" onChange={(e) => setTestNew(prev => ({ ...prev, valid_to: convertToBackendFormat(e.target.value) }))} className='p-2 outline-none border border-1 border-zinc-300 rounded-lg' value={formatDateForInput(testNew.valid_to)} />
                            </pre>
                        </div>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Time duration in minutes : </span>
                            <input type='number' onChange={(e) => setTestNew(prev => ({ ...prev, time_duration: parseFloat(e.target.value) }))} className='w-1/2 col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={testNew.time_duration} />
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Total marks : </span>
                            <input type='number' onChange={(e) => setTestNew(prev => ({ ...prev, total_marks: parseFloat(e.target.value) }))} className='w-1/2 col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={testNew.total_marks} />
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Question Paper : </span>
                            <div className='col-span-2 space-y-2 w-3/4 p-8 border border-dashed border-2 border-gray-400 rounded-lg bg-gray-100'>
                                <img className='mx-auto font-semibold text-sm' src='/upload.svg' alt='upload'/>
                                <p className='text-center font-semibold text-sm'>Upload Question Paper</p>
                                <div className='flex justify-center'>
                                    <input type='file' accept='application/pdf'
                                        className='hidden'
                                        id='questionFile'
                                        onChange={handleFileChange} />
                                    {(testNew.question_paper || selectedFile) ? <button onClick={handleButtonClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Replace</button> : <button onClick={handleButtonClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Browse</button>}
                                </div>
                                <p className='overflow-auto text-center'>{selectedFile?.name}</p>
                            </div>
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Answer Key : </span>
                            <div className='col-span-2 space-y-2 w-3/4 p-8 border border-dashed border-2 border-gray-400 rounded-lg bg-gray-100'>
                                <img className='mx-auto font-semibold text-sm' src='/upload.svg' alt='upload'/>
                                <p className='text-center font-semibold text-sm'>Upload Answer Key</p>
                                <div className='flex justify-center'>
                                    <input type='file' accept='application/pdf'
                                        className='hidden'
                                        id='answerFile'
                                        onChange={handleAnswerKeyChange} />
                                    {(testNew.answer_key || selectedAnswerKey) ? <button onClick={handleAnswerButtonClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Replace</button> : <button onClick={handleAnswerButtonClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Browse</button>}
                                </div>
                                <p className='overflow-auto text-center'>{selectedAnswerKey?.name}</p>
                            </div>
                        </pre>
                        {(testNew.test_title && testNew.valid_from && testNew.valid_to) && <button onClick={addTest} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white'>Create</button>}
                    </div>
                </main>
            </div>
            <ToastContainer />
        </div>
    );
}

export default CreateTest;