import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function SpecificPromotion() {

    interface promotionType {
        id: string,
        title: string,
        image_url: string,
    }
    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const { id } = useParams();
    const [status, setStatus] = useState<boolean>(true)
    const [promotion, setPromotion] = useState<promotionType>({
        id: "",
        title: "",
        image_url: "",
    })
    const [updatedPromotion, setUpdatedPromotion] = useState<promotionType>({
        id: "",
        title: "",
        image_url: "",
    })
    const [selectedFile, setSelectedFile] = useState<File>()
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/promotions/${id}`, config)
                .then((res) => {
                    setPromotion(res.data)
                    setUpdatedPromotion(res.data)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    if (err.response.status == 404)
                        setStatus(false)
                    console.log(err)
                })
        }
    }, [])

    async function updatePromotion() {
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        let banner = ""
        if (selectedFile) {
            const response = await BaseUrl.get(`promotions-presigned-url/?object_key=${selectedFile.name}`, config)
            const presignedUrl = response.data.url
            const uploadResponse = await axios.put(presignedUrl, selectedFile, {
                headers: {
                    'Content-Type': selectedFile.type,
                    'x-amz-acl': 'public-read',
                },
            });
            if (uploadResponse.status === 200) {
                const endIndex = presignedUrl.indexOf('?');
                const extractedString = presignedUrl.substring(61, endIndex);
                const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN
                banner = baseUrl + extractedString
            } else {
                alert("File upload failed");
            }
        }
        BaseUrl.patch(`admin/promotions/${id}/`, {
            image_url: banner || updatedPromotion.image_url,
            title: updatedPromotion.title,
        }, config)
            .then((res) => {
                setPromotion(res.data)
                setUpdatedPromotion(res.data)
                setSelectedFile(undefined)
                toast.success("Updated Succesfully")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
        setIsUpdating(false)
    }

    function deletePromotion() {
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        BaseUrl.delete(`admin/promotions/${id}/`, config)
            .then((res) => {
                toast.success("Deleted Succesfully")
                navHandler("/promotions")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
        setIsModalOpen(false)
    }

    const handleAttachClick = () => {
        const inpElement = document.getElementById("banner")
        inpElement?.click()
    };

    const handleFileChange = (event: any) => {
        // Handle the file selection
        const file = event.target.files[0];
        setSelectedFile(file)
    };

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Promotions' />
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 max-w-md mx-auto">
                        <h2 className="text-xl font-semibold text-gray-800 mb-6">
                            Are you sure you want to delete this promotion?
                        </h2>
                        <div className="flex justify-end gap-4">
                            <button
                                onClick={deletePromotion}
                                className="px-6 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 transition duration-200"
                            >
                                Yes
                            </button>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="px-6 py-2 bg-gray-300 text-gray-800 font-semibold rounded-md hover:bg-gray-400 transition duration-200"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Promotion
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <p className="text-blue-600">Promotion</p>
                            <span>›</span>
                            <span onClick={() => navHandler("/promotions")} className="text-blue-600 cursor-pointer">Promotions</span> <span>›</span>
                            {status ? <span>{promotion?.title}</span> : <span>Not Found</span>}
                        </nav>
                    </div>
                </header>
                {status ? <main className="grid grid-cols-4 flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="col-span-3 flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-11/12">
                        <pre className='w-full grid grid-cols-4 items-center'>
                            <span className='font-extrabold'>Title  : </span>
                            <input onChange={(e) => setUpdatedPromotion((prev) => ({ ...prev, title: e.target.value }))} className='col-span-3 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={updatedPromotion.title || '-'} />
                        </pre>
                        <pre className='w-full grid grid-cols-4 items-center'>
                            <span className='font-extrabold'>Banner : </span>
                            <img src={promotion.image_url} alt={promotion.title} className='col-span-3 py-2 px-8 w-11/12' />
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Upload new banner : </span>
                            <div className='col-span-2 space-y-2 w-3/4 p-8 border border-dashed border-2 border-gray-400 rounded-lg bg-gray-100'>
                                <img className='mx-auto font-semibold text-sm' src='/upload.svg' alt='upload' />
                                <p className='text-center font-semibold text-sm'>Upload new banner : </p>
                                <div className='flex justify-center'>
                                    <input type='file' accept="image/*"
                                        className='hidden'
                                        id='banner'
                                        onChange={handleFileChange} />
                                    {(updatedPromotion.image_url || selectedFile) ? <button onClick={handleAttachClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Replace</button> : <button onClick={handleAttachClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Browse</button>}
                                </div>
                                <p className='overflow-auto text-center'>{selectedFile?.name || (updatedPromotion.image_url)?.substring(72)}</p>
                            </div>
                        </pre>
                    </div>
                    <div className='my-4'>
                        {isUpdating ? (JSON.stringify(updatedPromotion) != JSON.stringify(promotion) || (selectedFile)) && <button className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white opacity-50 w-5/6'>Updating</button> : (JSON.stringify(updatedPromotion) != JSON.stringify(promotion) || (selectedFile)) && <button onClick={() => { setIsUpdating(true); updatePromotion() }} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white w-5/6'>Update</button>}
                        <button onClick={() => setIsModalOpen(true)} className='p-2 rounded-lg bg-red-600 text-lg font-semibold text-white w-5/6 my-4'>Delete</button>
                    </div>
                </main> : <div className='p-16 text-3xl font-extrabold'>No User Found With Id : {id}</div>}
            </div>
            <ToastContainer />
        </div>
    );
}

export default SpecificPromotion;
