import React from 'react';
import FeesRow from './FeesRow';
import { useAppSelector } from '../../redux/hooks/hooks';


const FeesTable: React.FC = () => {
    const fees = useAppSelector((state) => state.fees.results) || []
    return (
        <section>
            <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
                <div className="grid grid-cols-3 text-left w-full text-base font-bold leading-6 text-blue-600">
                    <p className='p-4'>Test Series Tyoe</p>
                    <p className='p-4'>Course Type</p>
                    <p className='p-4'>Attempts</p>
                </div>
                {(fees && fees.length>0) && fees.map((fees, index) => (
                    <FeesRow key={index} fees={fees} isEven={index % 2 === 0} />
                ))}
            </div>
        </section>
    );
};

export default FeesTable;