import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';

function SpecificOrder() {

    interface orderType {
        user: {
            phone: number,
            name: string,
            email: string,
        },
        created_at: string,
        order_value: number,
        order_status: string,
    }
    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const { id } = useParams();
    const [status, setStatus] = useState<boolean>(true)
    const [order, setOrder] = useState<orderType>({
        user: {
            phone: 0,
            name: "",
            email: ""
        },
        created_at: "",
        order_value: 0,
        order_status: "pending",

    })

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/orders/${id}`, config)
                .then((res) => {
                    setOrder(res.data)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    if (err.response.status == 404)
                        setStatus(false)
                    console.log(err)
                })
        }
    }, [])

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Orders' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Orders
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <a href="/orders" className="text-blue-600">Orders</a>
                            <span>›</span>
                            <span className='text-blue-600 cursor-pointer' onClick={()=>navHandler("/orders")}>Orders</span>
                            <span>›</span>
                           {status?<span>{order.user.phone}_{order.created_at}</span>:<span>Not Found</span>}
                        </nav>
                    </div>
                </header>
                {status ? <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-1/2 mx-auto">
                        <p><span className='font-extrabold'>User Name : </span> {order.user.name}</p>
                        <p><span className='font-extrabold'>User Email : </span> {order.user.email}</p>
                        <p><span className='font-extrabold'>User Phone : </span> {order.user.phone}</p>
                        <p><span className='font-extrabold'>Order value : </span> {order.order_value}</p>
                        <p><span className='font-extrabold'>Order status : </span> {order.order_status}</p>
                        <p><span className='font-extrabold'>Created at : </span> {order.created_at}</p>
                    </div>
                </main> : <div className='p-16 text-3xl font-extrabold'>No Order Found With Id : {id}</div>}
            </div>
        </div>
    );
}

export default SpecificOrder;
