import React from 'react';
import OrderRow from './OrderRow';
import { useAppSelector } from '../../redux/hooks/hooks';


const OrdersTable: React.FC = () => {
    const orders = useAppSelector((state) => state.orders.results) || []
    return (
        <section>
            <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
                <div className="grid grid-cols-4 text-left w-full text-base font-bold leading-6 text-blue-600">
                    <p className='p-4'>User</p>
                    <p className='p-4'>Order value</p>
                    <p className='p-4'>Order status</p>
                    <p className='p-4'>Created at</p>
                </div>
                {(orders && orders.length>0) && orders.map((order, index) => (
                    <OrderRow key={index} order={order} isEven={index % 2 === 0} />
                ))}
            </div>
        </section>
    );
};

export default OrdersTable;