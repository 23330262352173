import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SubmissionsState {
    count: number;
    previous: string | null;
    next: string | null;
    current: number;
    results: any[]; 
}

const initialState: SubmissionsState = {
    count: 0,
    previous: null,
    next: null,
    results: [],
    current: 1
};

export const SubmissionsSlice = createSlice({
    name: 'submissions', 
    initialState,
    reducers: {
        setSubmissions: (state, action: PayloadAction<SubmissionsState>) => {
            state.count = action.payload.count;
            state.previous = action.payload.previous;
            state.next = action.payload.next;
            state.results = action.payload.results;
            state.current = action.payload.current || 1;
        },
        setCurrentSubmissionPage: (state, action: PayloadAction<number>) => {
            state.current = action.payload;
        },
    },
});

export const { setSubmissions, setCurrentSubmissionPage } = SubmissionsSlice.actions;

export default SubmissionsSlice.reducer;