import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import DoubtRow from './DoubtRow';


const DoubtsTable: React.FC = () => {
    const doubts = useAppSelector((state) => state.doubt.results) || []
    return (
        <section>
            <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
                <div className="grid grid-cols-11 text-left w-full text-base font-bold leading-6 text-blue-600">
                    <p className='p-4 col-span-4'>Submission</p>
                    <p className='p-4 col-span-2'>User</p>
                    <p className='p-4 col-span-4'>Question</p>
                    <p className='p-4 col-span-1'>Status</p>
                </div>
                {doubts.map((doubt, index) => (
                    <DoubtRow key={index} doubt={doubt} isEven={index % 2 === 0} />
                ))}
            </div>
        </section>
    );
};

export default DoubtsTable;