import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { setSubmissions } from '../../redux/slices/submissions';

interface Teacher {
    id: number,
    first_name: string,
    last_name: string,
    role: string
}

interface Submission {
    id: number;
    test: {
        test_title: string,
        subject: {
            id: number
        }
    },
    user: {
        phone: number
    }
    assigned_teacher: Teacher;
    check_status: boolean;
}

interface SubmissionRowProps {
    submission: Submission;
    isEven: boolean;
    setTeacherChange : React.Dispatch<React.SetStateAction<boolean>>
    teacherChange : boolean
}

const SubmissionRow: React.FC<SubmissionRowProps> = ({ submission, isEven, teacherChange, setTeacherChange }) => {
    const token = useAppSelector((state: RootState) => state.auth.access)
    const current = useAppSelector((state : RootState) => state.submission.current)
    const dispatch = useAppDispatch()
    const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
    const navHandler = useNavigate()
    const [teachers, setTeachers] = useState<Teacher[]>([])
    useEffect(() => {
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };

        BaseUrl.get(`get-teacher-by-subject?object_key=${submission.test.subject.id}`, config)
            .then((res) => {
                setTeachers(res.data.teachers)
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
    }, [submission.test.subject.id])

    function selectTeacher(e: any) {
        let selected;
        if (e.target.value == "null")
        selected = "null";
        else
        selected = teachers[parseInt(e.target.value)].id
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        BaseUrl.patch(`admin/submission/${submission.id}/`, {
            assigned_teacher_id: selected
        }, config)
            .then((res) => {
                setTeacherChange(true)
                console.log("yes")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
    }
    return (
        <div className={`grid grid-cols-12 gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto`}>
            <div onClick={() => (navHandler(`/submission/${submission.id}`))} className="overflow-x-auto col-span-5 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5 ">
                {submission.test.test_title + " - " + submission.user.phone || '-'}
            </div>
            <div onClick={() => (navHandler(`/submission/${submission.id}`))} className="overflow-x-auto col-span-2 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {submission.user.phone || '-'}
            </div>
            <div className="overflow-x-auto col-span-3 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                <select onChange={(e) => selectTeacher(e)} className="col-span-2 w-full outline-none border border-1 border-zinc-300 p-2 rounded-lg">
                    {submission.assigned_teacher == null && <option value="null" selected = {submission.assigned_teacher == null}> ---- </option>}
                    {teachers.length > 0 && teachers.map((teacher, index) => (
                        teacher.role == "teacher" && <option value={index} selected={submission?.assigned_teacher?.id == teacher.id}>
                            {teacher.first_name} {teacher.last_name}
                        </option>
                    ))}
                </select>
            </div>
            <div onClick={() => (navHandler(`/submission/${submission.id}`))} className="overflow-x-auto col-span-2 justify-center items-start p-4 text-base leading-6 border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {submission.check_status ? <img src='/tick.svg' alt='true' /> : <img src='/redCross.svg' alt='false' />}
            </div>
        </div>
    );
};

export default SubmissionRow;