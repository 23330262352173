import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { setCurrentUserPage, setUsers } from '../../redux/slices/users';
import EmployeesTable from './EmployeesTable';
import { setEmployees } from '../../redux/slices/employees';

function Employees() {
  const navHandler = useNavigate()
  const token = useAppSelector((state : RootState) => state.auth.access)
  const prev = useAppSelector((state : RootState) => state.employee.previous)
  const next = useAppSelector((state : RootState) => state.employee.next)
  const current = useAppSelector((state : RootState) => state.employee.current)
  const dispatch = useAppDispatch()
  const [number, setNumber] = useState('');
  const [debouncedNumber, setDebouncedNumber] = useState(number);
  const [name, setName] = useState('');
  const [debouncedName, setDebouncedName] = useState(name);
  const [role, setRole] = useState('admin');
  
  useEffect(() => {
    if (!token) {
      navHandler("/login")
    }
    else {
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };
      BaseUrl.get(`filter-employees/?page=${current}&phone_number=${number}&name=${name}&role=${role}`, config)
        .then((res) => {
          const curr = res.data.current
          const modifiedData = {
            ...(res.data),
            current: curr
          };
          console.log(modifiedData)
          dispatch(setEmployees(modifiedData))
        })
      .catch((err)=>{
        if(err.response.status == 403)
        dispatch(setAccess(""))
        console.log(err) 
       })
    }
  }, [current,debouncedNumber,debouncedName,role])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedNumber(number);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [number]);
  
  useEffect(() => {
    const nameHandler = setTimeout(() => {
      setDebouncedName(name);
    }, 500);

    return () => {
      clearTimeout(nameHandler);
    };
  }, [name]);
  

  return (
    <div className="text-sm grid grid-cols-5">
      <Sidebar activeLabel='Employees' />
      <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Employees
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">Home</a>
              <span>›</span>
              <p className="text-blue-600">Employees</p>
              <span>›</span>
              <span>Employees</span>
            </nav>
          </div>
          <button onClick={()=>navHandler("create")} className="flex gap-2.5 items-center justify-center p-4 text-base text-center text-white bg-green-600 rounded">
            <img src='/addOrder.svg' />
            <span className="leading-[150%]">Add Employee</span>
          </button>
        </header>
        <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="my-6">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-700">Filters</p>
              <button
                onClick={() => {
                  setNumber('')
                  setName('')
                }}
                className="px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Clear All Filters
              </button>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-3 gap-6">
              <div>
                <label htmlFor="number" className="block text-sm font-medium text-gray-700 mb-2">Number:</label>
                <input
                  type="number"
                  id="number"
                  name="number"
                  placeholder="Enter Number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              {/* <div>
                <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-2">Role:</label>
                <select
                  id="role"
                  name="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">----</option>
                  <option value="teacher">teacher</option>
                  <option value="admin">admin</option>
                  <option value="doubt_solver">doubt solver</option>
                </select>
              </div> */}
            </div>
          </div>
          <div className='grid grid-cols-3 text-xs my-4 w-1/2 gap-4 font-extrabold'>
            {role == "admin" ?<p className='p-2 rounded-full border border-1 border-blue-600 bg-blue-200 text-blue-700 text-center cursor-pointer'>Admin</p> : <p onClick={()=>setRole('admin')} className='p-2 rounded-full border border-1 border-black text-center cursor-pointer'>Admin</p>}
            {role == "teacher" ?<p className='p-2 rounded-full border border-1 border-blue-600 bg-blue-200 text-blue-700 text-center cursor-pointer'>Teacher</p> : <p onClick={()=>setRole('teacher')} className='p-2 rounded-full border border-1 border-black text-center cursor-pointer'>Teacher</p>}
            {role == "doubt_solver" ?<p className='p-2 rounded-full border border-1 border-blue-600 bg-blue-200 text-center text-blue-700 cursor-pointer'>Doubt Solver</p> : <p onClick={()=>setRole('doubt_solver')} className='p-2 rounded-full border border-1 border-black text-center cursor-pointer'>Doubt Solver</p>}
          </div>
          <EmployeesTable role={role} />
        </main>
        <div className='flex gap-2 w-full justify-center items-center m-4'>
          {prev && <button onClick={()=>dispatch(setCurrentUserPage(current - 1))} className='px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer'>{"<"}</button>}
          <div className='px-2 py-1 rounded-lg border border-2 border-blue-400 text-xl text-white font-semibold bg-blue-600 cursor-pointer'>{current}</div>
          {next && <button onClick={()=>dispatch(setCurrentUserPage(current + 1))} className='px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer'>{">"}</button>}
        </div>
      </div>
    </div>
  );
}

export default Employees;
