import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthState, setAccess } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/hooks/hooks';

interface MenuItem {
  label: string;
  icon: string;
  route?: string
}

interface MenuSection {
  title: string;
  items: MenuItem[];
}

const menuSections: MenuSection[] = [
  // {
  //   title: 'Authentication and Authorization',
  //   items: [
  //     { label: 'Groups', icon: '/group.svg' },
  //   ]
  // },
  {
    title: 'Client',
    items: [
      { label: 'Users', icon: '/navDot.svg', route: '/users' },
    ]
  },
  {
    title: 'Coupons',
    items: [
      { label: 'Coupons', icon: '/navDot.svg', route: '/coupons' },
    ]
  },
  // {
  //   title: 'Courses/Subjects',
  //   items: [
  //     { label: 'Attemptss', icon: '/navDot.svg' },
  //     { label: 'Course types', icon: '/navDot.svg' },
  //     { label: 'Courses', icon: '/navDot.svg' },
  //     { label: 'Subjects', icon: '/navDot.svg' },
  //   ]
  // },
  {
    title: 'Employees',
    items: [
      { label: 'Employees', icon: '/navDot.svg', route: '/employees' },
    ]
  },
  {
    title: 'Orders',
    items: [
      { label: 'Orders', icon: '/navDot.svg', route: '/orders' },
    ]
  },
  {
    title: 'Promotions',
    items: [
      { label: 'Promotions', icon: '/navDot.svg', route: '/promotions' },
    ]
  },
  {
    title: 'Report',
    items: [
      { label: 'Doubts', icon: '/navDot.svg', route: '/doubts' },
      { label: 'Submissions', icon: '/navDot.svg', route: '/submissions' },
    ]
  },
  {
    title: 'Testseries',
    items: [
      { label: 'Fees structure and syllabuss', icon: '/navDot.svg', route: '/fees-and-syllabus' },
      { label: 'Test series types', icon: '/navDot.svg', route: '/test-series-types'  },
      { label: 'Test series', icon: '/navDot.svg', route: '/test-series' },
      { label: 'Tests', icon: '/navDot.svg', route: '/tests' },
    ]
  },
  {
    title: 'Transactions',
    items: [
      { label: 'Payement Information', icon: '/navDot.svg', route: '/payment-information' },
      { label: 'Transactions', icon: '/navDot.svg', route: '/transactions'  },
    ]
  },
];

interface Props {
  activeLabel: string
}

const Sidebar: React.FC<Props> = ({ activeLabel }) => {
  
  const navHandler = useNavigate()
  const phone = useAppSelector((state) => state.auth.phone)
  const dispatch = useAppDispatch()
  const role = useAppSelector((state) => state.auth.role)

  return (
    <div>
      <aside className="fixed top-0 z-10 flex flex-col w-1/5 overflow-x-hidden overflow-y-auto h-[100vh]">
        <div className="flex flex-col grow pb-20 mx-auto w-full shadow-xl bg-[#343A40]">
          <div className="flex gap-2 items-center px-5 py-4 text-xl font-light leading-8 border-b border-gray-600 border-solid text-white text-opacity-80">
            <img loading="lazy" src="/logo.png" className="shrink-0 shadow-lg aspect-square w-[33px]" alt="" />
            <div className="">Gradehunt admin</div>
          </div>
          <div className="flex z-10 gap-2.5 items-start px-3.5 pt-px pb-5 border-b border-gray-600 border-solid">
            <div className="text-4xl leading-10 text-white"></div>
            <div className="flex-auto mt-3 text-base leading-6 text-neutral-300">
              {phone}
            </div>
          </div>
          <nav className="flex flex-col px-6 mt-7 text-neutral-300 max-md:px-5">
            <div className="flex gap-2.5 whitespace-nowrap">
              <img className="" src='/dashboard.svg' alt='dashboard' />
              <div className="">Dashboard</div>
            </div>
            {role == "admin" && <div className='text-left'>
              {menuSections.map((section, index) => (
                <React.Fragment key={index}>
                  <div className="mt-8 text-xs leading-5 text-white text-opacity-30">
                    {section.title}
                  </div>
                  {section.items.map((item, itemIndex) => (
                    <div onClick={()=>navHandler(`${item.route}`)} className={`flex ${item.label === activeLabel && 'bg-blue-600 rounded shadow'} cursor-pointer gap-4 p-2 my-2 items-center gap-1 whitespace-nowrap`}>
                      <img className="" src={item.icon} alt="item icon"/>
                      <div
                        key={itemIndex}
                        className={'p-2 overflow-x-auto'}
                      >
                        <div className="text-base leading-6">{item.label}</div>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>}
            {role == "teacher" && <div className='text-left'>
                <React.Fragment>
                  <div className="mt-8 text-xs leading-5 text-white text-opacity-30">
                    Report
                  </div>
                    <div onClick={()=>navHandler(`submissions`)} className={`flex  bg-blue-600 rounded shadow cursor-pointer gap-4 p-2 my-2 items-center gap-1 whitespace-nowrap`}>
                      <img className="" src={"navDot.svg"} alt="item icon"/>
                      <div
                        className={'p-2 overflow-x-auto'}
                      >
                        <div className="text-base leading-6">Submissions</div>
                      </div>
                    </div>
                </React.Fragment>
            </div>}
            {role == "doubt_solver" && <div className='text-left'>
                <React.Fragment>
                  <div className="mt-8 text-xs leading-5 text-white text-opacity-30">
                    Report
                  </div>
                    <div onClick={()=>navHandler(`doubts`)} className={`flex  bg-blue-600 rounded shadow cursor-pointer gap-4 p-2 my-2 items-center gap-1 whitespace-nowrap`}>
                      <img className="" src={"navDot.svg"} alt="item icon"/>
                      <div
                        className={'p-2 overflow-x-auto'}
                      >
                        <div className="text-base leading-6">Doubts</div>
                      </div>
                    </div>
                </React.Fragment>
            </div>}
            <div>
              <p className='cursor-pointer text-center font-extrabold text-red-500' onClick={()=>{dispatch(setAccess(""));navHandler("/login")}}>Logout</p>
            </div>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;