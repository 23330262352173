import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TransactionState {
    count: number;
    previous: string | null;
    next: string | null;
    current: number;
    results: any[]; 
}

const initialState: TransactionState = {
    count: 0,
    previous: null,
    next: null,
    results: [],
    current: 1
};

export const transactionsSlice = createSlice({
    name: 'transactions', 
    initialState,
    reducers: {
        setTranscations: (state, action: PayloadAction<TransactionState>) => {
            state.count = action.payload.count;
            state.previous = action.payload.previous;
            state.next = action.payload.next;
            state.results = action.payload.results;
            state.current = action.payload.current || 1;
        },
        setCurrentTransactionPage: (state, action: PayloadAction<number>) => {
            state.current = action.payload;
        },
    },
});

export const { setTranscations, setCurrentTransactionPage } = transactionsSlice.actions;

export default transactionsSlice.reducer;