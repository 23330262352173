import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateTestSeries() {

    interface attemptInterface {
        id: number,
        month?: string,
        year?: string
    }

    interface courseTypeInterface {
        id?: number,
        title?: string,
        attempts?: Array<attemptInterface>,
    }

    interface testSeriesTypeInterface {
        id?: number,
        title?: string,
    }

    interface testSeriesInterface {
        discounted_price: number,
        mrp_price: number,
        title: string,
        description: string,
        course_type: courseTypeInterface,
        status: boolean,
        test_series_type: testSeriesTypeInterface,
        test_series_attempts: Array<attemptInterface>,
        valid_from: string,
        valid_till: string,
    }

    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const { id } = useParams();
    const [status, setStatus] = useState<boolean>(true)
    const [testSeries, setTestSeries] = useState<testSeriesInterface>({
        discounted_price: 0,
        mrp_price: 0,
        title: "",
        description: "",
        course_type: {},
        status: false,
        test_series_type: {},
        test_series_attempts: [],
        valid_from: "",
        valid_till: "",
    })
    const [testSeriesNew, setTestSeriesNew] = useState<testSeriesInterface>({
        discounted_price: 0,
        mrp_price: 0,
        title: "",
        description: "",
        course_type: {},
        status: false,
        test_series_type: {},
        test_series_attempts: [],
        valid_from: "",
        valid_till: "",
    })
    const [courseType, setCourseType] = useState<courseTypeInterface[]>([])
    const [courseTypeId, setCourseTypeId] = useState<number>()
    const [testSeriesType, setTestSeriesType] = useState<courseTypeInterface[]>([])
    const [testSeriesTypeId, setTestSeriesTypeId] = useState<number>()
    const [isListVisible, setIsListVisible] = useState(false);
    const [selectedAttempts, setSelectedAttempts] = useState<number[]>([]);
    const [availableAttempts, setAvailableAttempts] = useState<attemptInterface[]>([]);

    const toggleListVisibility = () => {
        setIsListVisible(!isListVisible);
    };

    const handleCheckboxChange = (event: { target: { checked: any; }; }, attemptId: number) => {
        if (event.target.checked) {
            setSelectedAttempts([...selectedAttempts, attemptId]);
        } else {
            setSelectedAttempts(selectedAttempts.filter(id => id !== attemptId));
        }
    };

    const removeSelectedAttempt = (attemptId: number) => {
        setSelectedAttempts(selectedAttempts.filter(id => id !== attemptId));
    };

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/non-paginated-course-type/`, config)
                .then((res) => {
                    setCourseType(res.data)
                    setAvailableAttempts((res.data.results[0]).attempts)
                })
                .catch((err) => {
                    if (err.response?.status == 403)
                        dispatch(setAccess(""))
                    if (err.response?.status == 404)
                        setStatus(false)
                    console.log(err)
                })
            BaseUrl.get(`admin/non-paginated-test-series-type/`, config)
                .then((res) => {
                    setTestSeriesType(res.data)
                })
                .catch((err) => {
                    if (err.response?.status == 403)
                        dispatch(setAccess(""))
                    if (err.response?.status == 404)
                        setStatus(false)
                    console.log(err)
                })
        }
    }, [])

    useEffect(() => {
        const selectedCourse = courseType.find(course => course.id == (courseTypeId || courseType[0].id));
        setAvailableAttempts(selectedCourse?.attempts || [])
        setSelectedAttempts([])
    }, [courseTypeId])

    function creatTestSeries () {
        const config = {
            headers: {
                Authorization: `${token}`,
                'Content-Type': 'application/json',
            },
        };
        BaseUrl.post(`admin/test-series/`, {
            course_type_id: courseTypeId || courseType[0].id,
            test_series_type_id: testSeriesTypeId || testSeriesType[0].id,
            title: testSeriesNew.title,
            description: testSeriesNew.description,
            valid_from: testSeriesNew.valid_from,
            valid_till: testSeriesNew.valid_till,
            discounted_price: testSeriesNew.discounted_price || 0,
            mrp_price: testSeriesNew.mrp_price || 0,
            status: testSeriesNew?.status || false,
            test_series_attempts_ids: selectedAttempts
        }, config)
            .then((res) => {
                setTestSeriesNew(res.data)
                navHandler(`/test-series/${res.data.id}`)
                toast.success("Created Succesfully")
            })
            .catch((err) => {
                if (err.response?.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
    }

    const formatDateForInput = (dateString: string) => {
        if (!dateString) {
            return '';  // Return an empty string if the date is null or undefined
        }
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const convertToBackendFormat = (localDateTime: string) => {
        const date = new Date(localDateTime);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;  // Return only the date part
    };    

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Test series' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Test Series
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <p className="text-blue-600">Testseries</p>
                            <span>›</span>
                            <span>Test series - </span>
                            <span>Create</span>
                        </nav>
                    </div>
                </header>
                <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-11/12 mx-auto">
                        <pre className='w-full text-sm grid grid-cols-5 items-center'>
                            <span className='font-extrabold'>Title : </span>
                            <input onChange={(e) => setTestSeriesNew(prev => ({ ...prev, title: e.target.value }))} className='col-span-4 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={testSeriesNew.title } />
                        </pre>
                        <pre className='w-full text-sm grid grid-cols-5'>
                            <span className='font-extrabold'>Description : </span>
                            <textarea onChange={(e) => setTestSeriesNew(prev => ({ ...prev, description: e.target.value }))} className='h-[15vh] col-span-4 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={testSeriesNew.description } />
                        </pre>
                        {testSeriesType && <pre className='w-full text-sm grid grid-cols-5 items-center'>
                            <span className='font-extrabold'>Test Series type : </span>
                            <select onChange={(e) => {
                                setTestSeriesTypeId(parseInt(e.target.value));
                                const selectedTestSeriesTypeId = parseInt(e.target.value)
                                const selectedTestSeriesType = Object.values(testSeriesType).find(testSeriesType => testSeriesType.id == selectedTestSeriesTypeId);
                                setTestSeriesNew(prev => ({ ...prev, test_series_type: selectedTestSeriesType || {} }))
                            }} className='w-full col-span-2 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {Object.entries(testSeriesType).map(([key, testSeriesType]) => (
                                    testSeriesType && testSeriesType.id && (
                                        <option className='w-full' key={key} value={testSeriesType.id} selected={testSeriesType.id == testSeries.test_series_type.id}>
                                            {testSeriesType.id} - {testSeriesType.title}
                                        </option>
                                    )
                                ))}
                            </select>
                        </pre>}
                        {courseType && <pre className='w-full text-sm grid grid-cols-5 items-center'>
                            <span className='font-extrabold'>Course type : </span>
                            <select onChange={(e) => {
                                setCourseTypeId(parseInt(e.target.value));
                                const selectedCourseId = parseInt(e.target.value)
                                const selectedCourse = Object.values(courseType).find(course => course.id == selectedCourseId);
                                setTestSeriesNew(prev => ({ ...prev, course_type: selectedCourse || {} }))
                            }} className='w-full col-span-2 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {Object.entries(courseType).map(([key, course]) => (
                                    course && course.id && (
                                        <option className='w-full' key={key} value={course.id} selected={course.id == testSeries.course_type.id}>
                                            {course.id} - {course.title}
                                        </option>
                                    )
                                ))}
                            </select>
                        </pre>}
                        <pre className='w-full text-sm grid grid-cols-5'>
                            <span className='font-extrabold'>Attempts : </span>
                            <div className='grid grid-cols-2 gap-2 w-1/2 col-span-4 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {testSeriesNew.course_type.attempts && testSeriesNew.course_type.attempts
                                    .filter(attempt => selectedAttempts.includes(attempt.id))
                                    .map((attempt) => (
                                        <div key={attempt.id} className='mb-2 flex text-xs bg-gray-200 w-fit p-2 border border-1 border-gray-400 rounded-lg'>
                                            <div
                                                className='pr-2 border-r-2 border-gray-400 mr-2 cursor-pointer'
                                                onClick={() => removeSelectedAttempt(attempt.id)}
                                            >
                                                x
                                            </div>
                                            {attempt.id} - {attempt.month}, {attempt.year}
                                        </div>
                                    ))}
                            </div>
                            <div className='grid grid-cols-12 my-2 p-2 col-start-2 col-span-2'>
                                {!isListVisible ? <img onClick={toggleListVisibility} className='cursor-pointer' src='/add.svg' alt='add' /> : <img onClick={toggleListVisibility} className='cursor-pointer w-1/2' src='/cross.svg' alt='add' />}
                                {isListVisible && (
                                    <div className='col-span-11 mx-auto p-4 border border-2 border-gray-500 rounded-lg'>
                                        {availableAttempts.map(attempt => (
                                            <div key={attempt.id}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        value={attempt.id}
                                                        onChange={(e) => handleCheckboxChange(e, attempt.id)}
                                                        className='mr-4'
                                                        checked={selectedAttempts.includes(attempt.id)}
                                                    />
                                                    {attempt.id} - {attempt.month}, {attempt.year}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </pre>
                        <div className='w-full items-center'>
                                <pre className='w-full grid grid-cols-5 items-center'>
                                    <span className='font-extrabold'>Valid from : </span>
                                    <input type="datetime-local" onChange={(e) => setTestSeriesNew(prev => ({ ...prev, valid_from: convertToBackendFormat(e.target.value) }))} className='col-span-2 p-2 outline-none border border-1 border-zinc-300 rounded-lg' value={formatDateForInput(testSeriesNew.valid_from)} />
                                </pre>
                            </div>
                            <div className='w-full items-center'>
                                <pre className='w-full grid grid-cols-5 items-center'>
                                    <span className='font-extrabold'>Valid Till : </span>
                                    <input type="datetime-local" onChange={(e) => setTestSeriesNew(prev => ({ ...prev, valid_till: convertToBackendFormat(e.target.value) }))} className='col-span-2 p-2 outline-none border border-1 border-zinc-300 rounded-lg' value={formatDateForInput(testSeriesNew.valid_till)} />
                                </pre>
                            </div>
                        <pre className='w-full text-sm grid grid-cols-5 items-center'>
                            <span className='font-extrabold'>Status : </span>
                            <input type='checkbox' onChange={(e) => setTestSeriesNew(prev => ({ ...prev, status: e.target.checked }))} className='outline-none border border-1 border-zinc-300 rounded-lg' checked={testSeriesNew?.status || false} />
                        </pre>
                        <pre className='w-full text-sm grid grid-cols-5 items-center'>
                            <span className='font-extrabold'>Discounted price : </span>
                            <input onChange={(e) => setTestSeriesNew(prev => ({ ...prev, discounted_price: parseFloat(e.target.value) }))} className='w-1/2 col-span-4 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={testSeriesNew.discounted_price} />
                        </pre>
                        <pre className='w-full text-sm grid grid-cols-5 items-center'>
                            <span className='font-extrabold'>Mrp price : </span>
                            <input onChange={(e) => setTestSeriesNew(prev => ({ ...prev, mrp_price: parseFloat(e.target.value) }))} className='w-1/2 col-span-4 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={testSeriesNew.mrp_price} />
                        </pre>
                        {(testSeriesNew.title && testSeriesNew.description && testSeriesNew.valid_from && testSeriesNew.valid_till) && <button onClick={creatTestSeries} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white'>Create</button>}
                    </div>
                </main>
            </div>
            <ToastContainer />
        </div>
    );
}

export default CreateTestSeries;