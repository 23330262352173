import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import TestSeriesTypeRow from './TestTypesRow';


const TestSeriesTypeTable: React.FC = () => {
    const testTypes = useAppSelector((state) => state.testTypes.results) || []
    return (
        <section>
            <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
                <div className="grid grid-cols-3 text-left w-full text-base font-bold leading-6 text-blue-600">
                    <p className='p-4'>Test Series Type</p>
                </div>
                {testTypes.map((type, index) => (
                    <TestSeriesTypeRow key={index} testTypes={type} isEven={index % 2 === 0} />
                ))}
            </div>
        </section>
    );
};

export default TestSeriesTypeTable;