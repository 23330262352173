import React from 'react';
import { useNavigate } from 'react-router-dom';

interface TestSeries {
    id: string;
    title: string;
    discounted_price: number;
    mrp_price: number;
}

interface TestSeriesRowProps {
    testSeries: TestSeries;
    isEven: boolean;
}

const TestSeriesRow: React.FC<TestSeriesRowProps> = ({ testSeries, isEven }) => {
    const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
    const navHandler = useNavigate()
    return (
        <div onClick={()=>(navHandler(`/test-series/${testSeries.id}`))} className={`grid grid-cols-8 gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto`}>
            <div className="justify-center items-start p-4 text-base font-bold leading-6 text-blue-600 whitespace-nowrap border-t border-solid border-zinc-200 max-md:pr-5">
                {testSeries.id}
            </div>
            <div className="col-span-3 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {testSeries.title || '-'}
            </div>
            <div className="col-span-2 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {testSeries.discounted_price}
            </div>
            <div className="col-span-2 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {testSeries.mrp_price || '-'}
            </div>
            {/* <div className="col-span-3 justify-center items-start p-4 text-base leading-6 border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {testSeries.email || '-'}
            </div> */}
        </div>
    );
};

export default TestSeriesRow;