import React from 'react';
import orders from '../../redux/slices/orders';
import { useNavigate } from 'react-router-dom';

interface Order {
    id: string;
    order_value: string;
    order_status: string;
    created_at: string;
    user : {
        phone : number,
    }
}

interface OrderRowProps {
    order: Order;
    isEven: boolean;
}

const OrderRow: React.FC<OrderRowProps> = ({ order, isEven }) => {
    const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
    const navHandler = useNavigate()
    
    function formatDateTime(dateTimeString: string): string {
        const date = new Date(dateTimeString);
        const formattedDate = date.toLocaleString(); // Adjust this to format as per your needs
        return formattedDate;
    }

    return (
        <div onClick={()=>(navHandler(`/order/${order.id}`))} className={`grid grid-cols-4 gap-0 justify-center ${rowClass} text-left cursor-pointer`}>
            {order.user && <div className="justify-center items-start px-3 py-5 text-base font-bold leading-6 text-blue-600 whitespace-nowrap border-t border-solid border-zinc-200 max-md:pr-5">
                {order.user.phone}
            </div>}
            <div className="justify-center items-start px-3 py-5 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {order.order_value}
            </div>
            {order.order_status == "Completed" ? <div className="font-semibold justify-center items-start px-3 py-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-green-500 max-md:pr-5">
                {order.order_status}
            </div> : <div className="font-semibold justify-center items-start px-3 py-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-red-500 max-md:pr-5">
                {order.order_status}
            </div>}
            <div className="justify-center items-start px-3 py-4 text-base leading-6 border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {formatDateTime(order.created_at)}
            </div>
        </div>
    );
};

export default OrderRow;