import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateTestType() {

    interface testTypeType {
        id: string,
        title: string,
        description: string,
    }

    const navHandler = useNavigate();
    const token = useAppSelector((state: RootState) => state.auth.access);
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [status, setStatus] = useState<boolean>(true);
    const [testType, setTestSeriesType] = useState<testTypeType>({
        id: "",
        title: "",
        description: ""
    });
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string[]>(["", "", "", "", ""]);
    const [errorLines, setErrorLines] = useState<number[]>([]);

    useEffect(() => {
        if (!token) {
            navHandler("/login");
        } else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/test-series-type/`, config)
                .then((res) => {
                    setTestSeriesType(res.data);
                })
                .catch((err) => {
                    if (err.response.status === 403)
                        dispatch(setAccess(""));
                    if (err.response.status === 404)
                        setStatus(false);
                    console.log(err);
                });
        }
    }, [token, navHandler, dispatch]);

    function updateDescription(point: string, index: number) {
        setDescription(prevDescription => {
            const updatedDescription = [...prevDescription];
            updatedDescription[index] = point;
            return updatedDescription;
        });
        if (point.includes(',')) {
            setErrorLines(prevErrorLines => {
                if (!prevErrorLines.includes(index)) {
                    return [...prevErrorLines, index];
                }
                return prevErrorLines;
            });
        } else {
            setErrorLines(prevErrorLines => {
                return prevErrorLines.filter(lineIndex => lineIndex !== index);
            });
        }
    }

    function addDescriptionLine() {
        if (description.length < 5) {
            setDescription([...description, ""]);
        }
    }

    function deleteDescriptionLine(index: number) {
        if (description.length > 1) {
            setDescription(prevDescription => prevDescription.filter((_, i) => i !== index));
        }
    }

    function createTestType() {
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        BaseUrl.post(`admin/test-series-type/`, {
            title: title,
            description: description.filter(line => line.trim() !== "").join(", ")
        }, config)
            .then((res) => {
                navHandler(`/test-series-type/${res.data.id}`);
                toast.success("Created Successfully");
            })
            .catch((err) => {
                if (err.response.status === 403)
                    dispatch(setAccess(""));
                console.log(err);
            });
    }

    useEffect(() => {
        const newValue = testType.description || "";
        const newSentences = newValue.split(',').map(sentence => sentence.trim());
        setDescription(newSentences.length ? newSentences : ["", "", "", "", ""]);
    }, [testType.description]);

    const isCreateButtonEnabled = description.every(line => line.trim() !== "") && !errorLines.length;

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='testTypes' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Test Series Types
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <span className="text-blue-600">Test Series</span>
                            <span>›</span>
                            <a href="/test-series-types" className="text-blue-600">TestSeries Types</a>
                            <span>›</span>
                            <span>Create</span>
                        </nav>
                    </div>
                </header>
                <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-5/6 mx-auto">
                        <pre className='w-full text-sm grid grid-cols-5 items-center'>
                            <span className='font-extrabold'>Title : </span>
                            <input onChange={(e) => setTitle(e.target.value)} className='col-span-4 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={title || testType.title} />
                        </pre>
                        <pre className='w-full text-sm grid grid-cols-5'>
                            <span className='font-extrabold'>Description: </span>
                            <ul className='list-disc col-span-4 flex flex-col gap-4 w-full'>
                                {description.map((desc, index) => (
                                    <li key={index} className='flex items-center gap-2'>
                                        <input
                                            maxLength={75}
                                            onChange={(e) => updateDescription(e.target.value, index)}
                                            className='w-full outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg'
                                            value={desc || ""}
                                        />
                                        {description.length>1 && <button
                                            onClick={() => deleteDescriptionLine(index)}
                                            className='text-red-600 ml-2'
                                        >
                                            Delete
                                        </button>}
                                        {errorLines.includes(index) && <div className='text-sm text-red-500 p-2'>Input should not contain a comma.</div>}
                                    </li>
                                ))}
                            </ul>
                        </pre>
                        {description.length < 5 && (
                            <button
                                onClick={addDescriptionLine}
                                className='mt-2 p-2 rounded-lg bg-green-600 text-lg font-semibold text-white mx-auto w-1/2'
                            >
                                Add Description Line
                            </button>
                        )}
                    </div>
                    {isCreateButtonEnabled && (
                        <button onClick={createTestType} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white w-1/4 mx-auto mt-4'>
                            Create
                        </button>
                    )}
                </main>
            </div>
            <ToastContainer />
        </div>
    );
}

export default CreateTestType;