import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';

function PaymentInformation() {

    interface paymentType {
        razor_key_id: string,
        razor_key_secret: string,
    }
    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const { id } = useParams();
    const [status, setStatus] = useState<boolean>(true)
    const [keyId, setKeyId] = useState<string>()
    const [keySecret, setKeySecret] = useState<string>()
    const [payment, setPayment] = useState<paymentType>({
        razor_key_id: "",
        razor_key_secret: "",
    })

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/payment-information/`, config)
                .then((res) => {
                    if (res.data.results.length == 0)
                        updateInfo()
                    else {
                        setPayment(res.data.results[0])
                        setKeyId(res.data.results[0].razor_key_id)
                        setKeySecret(res.data.results[0].razor_key_secret)
                    }
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    if (err.response.status == 404)
                        setStatus(false)
                    else
                        toast.error(err.response.data)
                    console.log(err)
                })
        }
    }, [])

    function updateInfo() {
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        BaseUrl.post(`admin/payment-information/`, {
            razor_key_id: keyId || payment.razor_key_id,
            razor_key_secret: keySecret || payment.razor_key_secret,
        }, config)
            .then((res) => {
                setPayment(res.data)
                setKeyId("")
                setKeySecret("")
                toast.success("Updated Succesfully")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                toast.error("razor_key_secret :" + (err.response.data)["razor_key_secret"][0])
            })
    }

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Payement Information' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Transactions
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <p className="text-blue-600">Transactions</p>
                            <span>›</span>
                            <span className='text-blue-600'>Payment Information</span>
                            <span>›</span>
                            {status ? <span>{payment.razor_key_id}</span> : <span>Not Found</span>}
                        </nav>
                    </div>
                </header>
                {status ? <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-4/5 mx-auto">
                        <pre className='w-full grid grid-cols-4 items-center'>
                            <span className='font-extrabold'>Razor key id  : </span>
                            <input onChange={(e) => { setKeyId(e.target.value) }} className='col-span-3 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={keyId || payment.razor_key_id || '-'} />
                        </pre>
                        <pre className='w-full grid grid-cols-4 items-center'>
                            <span className='font-extrabold'>Razor key secret : </span>
                            <input onChange={(e) => setKeySecret(e.target.value)} className='col-span-3 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={keySecret || payment.razor_key_secret || '-'} />
                        </pre>
                        {((keyId || keySecret) && (payment.razor_key_id != keyId || payment.razor_key_secret != keySecret)) && <button onClick={updateInfo} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white'>Update</button>}
                    </div>
                </main> : <div className='p-16 text-3xl font-extrabold'>No User Found With Id : {id}</div>}
            </div>
            <ToastContainer />
        </div>
    );
}

export default PaymentInformation;
