import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Coupon {
    id: string;
    title: string;
    discount_percent: string;
    code: string;
    status: boolean
}

interface CouponRowProps {
    coupon: Coupon;
    isEven: boolean;
}

const CouponRow: React.FC<CouponRowProps> = ({ coupon, isEven }) => {
    const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
    const navHandler = useNavigate()

    return (
        <div onClick={()=>(navHandler(`/coupons/${coupon.id}`))} className={`grid grid-cols-4 gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto`}>
            <div className="col-span-2 justify-center items-start p-4 text-base font-bold leading-6 text-blue-600 whitespace-nowrap border-t border-solid border-zinc-200 max-md:pr-5">
                {coupon.title} - {coupon.discount_percent}%
            </div>
            <div className="justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {coupon.code || "-"}
            </div>
            <div className="overflow-x-auto col-span-1 justify-center items-start p-4 text-base leading-6 border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {coupon.status ? <img src='/tick.svg' /> : <img src='/redCross.svg' />}
            </div>
        </div>
    );
};

export default CouponRow;