import { configureStore } from '@reduxjs/toolkit'
import { authSlice, AuthState } from './slices/auth'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import { ordersSlice, OrdersState } from './slices/orders';
import { usersSlice, UsersState } from './slices/users';
import { transactionsSlice, TransactionState } from './slices/transactions';
import { TestsTypeState, testTypesSlice } from './slices/tests-types';
import { testSeriesSlice, TestSeriesState } from './slices/test-series';
import { testSlice, TestState } from './slices/tests';
import { employeesSlice, EmployeesState } from './slices/employees';
import { DoubtsSlice, DoubtsState } from './slices/doubts';
import { SubmissionsSlice, SubmissionsState } from './slices/submissions';
import { promotionsSlice, PromotionsState } from './slices/promotions';
import { couponsSlice, CouponsState } from './slices/coupons';
import { feesSlice, FeesState } from './slices/fees';

const persistConfig = {
    key: 'root',
    version : 1,
    storage,
}

const rootReducer = combineReducers({
    auth: authSlice.reducer,
    orders : ordersSlice.reducer,
    users : usersSlice.reducer,
    transactions : transactionsSlice.reducer,
    testTypes : testTypesSlice.reducer,
    testSeries: testSeriesSlice.reducer,
    test: testSlice.reducer,
    employee: employeesSlice.reducer,
    doubt: DoubtsSlice.reducer,
    submission : SubmissionsSlice.reducer,
    promotion : promotionsSlice.reducer,
    coupon : couponsSlice.reducer,
    fees : feesSlice.reducer
});

export type RootState = {
    auth: AuthState & { _persist: PersistPartial };
    orders: OrdersState & { _persist: PersistPartial };
    users: UsersState & { _persist: PersistPartial };
    transactions: TransactionState & { _persist: PersistPartial };
    testTypes: TestsTypeState & { _persist: PersistPartial };
    testSeries: TestSeriesState & { _persist: PersistPartial };
    test: TestState & { _persist: PersistPartial };
    employee: EmployeesState & { _persist: PersistPartial };
    doubt: DoubtsState & { _persist: PersistPartial };
    submission : SubmissionsState & { _persist: PersistPartial };
    promotion : PromotionsState & { _persist: PersistPartial };
    coupon : CouponsState & { _persist: PersistPartial };
    fees : FeesState & { _persist: PersistPartial };
};

const authPersistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: authPersistedReducer,
})

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch