import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SpecificCoupon() {
  interface couponType {
    id: string;
    title: string;
    discount_percent: number;
    code: string;
    status: boolean;
  }
  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState<boolean>(true);
  const [coupon, setCoupon] = useState<couponType>({
    id: '',
    title: '',
    discount_percent: 0,
    code: '',
    status: false,
  });
  const [updatedCoupon, setUpdatedCoupon] = useState<couponType>({
    id: '',
    title: '',
    discount_percent: 0,
    code: '',
    status: false,
  });
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.get(`admin/coupons/${id}`, config)
        .then((res) => {
          setCoupon(res.data);
          setUpdatedCoupon(res.data);
        })
        .catch((err) => {
          if (err.response.status == 403) dispatch(setAccess(''));
          if (err.response.status == 404) setStatus(false);
          console.log(err);
        });
    }
  }, []);

  async function updateCoupon() {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    BaseUrl.put(
      `admin/coupons/${id}/`,
      {
        discount_percent: updatedCoupon.discount_percent,
        title: updatedCoupon.title,
        status: updatedCoupon.status,
        code: updatedCoupon.code,
      },
      config
    )
      .then((res) => {
        setCoupon(res.data);
        setUpdatedCoupon(res.data);
        toast.success('Updated Succesfully');
      })
      .catch((err) => {
        if (err.response) {
          console.log('Error status:', err.response.status);
          console.log('Error data:', err.response.data);
          if (err.response.status === 403) {
            dispatch(setAccess(''));
          }
        } else {
          console.log('Unknown error:', err);
        }
      });
    setIsUpdating(false);
  }

  function deleteCoupon() {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    BaseUrl.delete(`admin/coupons/${id}/`, config)
      .then((res) => {
        toast.success('Deleted Succesfully');
        navHandler('/coupons');
      })
      .catch((err) => {
        if (err.response.status == 403) dispatch(setAccess(''));
        console.log(err);
      });
    setIsModalOpen(false);
  }

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Coupons" />
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-gray-800 mb-6">
              Are you sure you want to delete this coupon?
            </h2>
            <div className="flex justify-end gap-4">
              <button
                onClick={deleteCoupon}
                className="px-6 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 transition duration-200"
              >
                Yes
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-6 py-2 bg-gray-300 text-gray-800 font-semibold rounded-md hover:bg-gray-400 transition duration-200"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col sm:flex-row gap-5 mx-auto sm:mx-0 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center sm:justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Coupon
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">Coupon</p>
              <span>›</span>
              <span
                onClick={() => navHandler('/coupons')}
                className="text-blue-600 cursor-pointer"
              >
                Coupons
              </span>
              <span>›</span>
              {status ? <span>{coupon?.title}</span> : <span>Not Found</span>}
            </nav>
          </div>
        </header>
        {status ? (
          <main className="grid grid-cols-1 md:grid-cols-4 gap-4 p-4 md:p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="md:col-span-3 flex flex-col gap-4 p-4 md:p-8 mt-4 bg-white rounded-xl shadow-xl w-full">
              {/* Title Input */}
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center gap-4">
                <span className="font-extrabold">Title: </span>
                <input
                  onChange={(e) =>
                    setUpdatedCoupon((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                  className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-4 rounded-lg"
                  value={updatedCoupon.title}
                />
              </pre>

              {/* Discount Percentage Input */}
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center gap-4">
                <span className="font-extrabold">Discount Percentage: </span>
                <input
                  type="number"
                  onChange={(e) =>
                    setUpdatedCoupon((prev) => ({
                      ...prev,
                      discount_percent: parseFloat(e.target.value),
                    }))
                  }
                  className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-4 rounded-lg"
                  value={updatedCoupon.discount_percent}
                />
              </pre>

              {/* Code Input */}
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center gap-4">
                <span className="font-extrabold">Code: </span>
                <input
                  onChange={(e) =>
                    setUpdatedCoupon((prev) => ({
                      ...prev,
                      code: e.target.value,
                    }))
                  }
                  className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-4 rounded-lg"
                  value={updatedCoupon.code}
                />
              </pre>

              {/* Status Checkbox */}
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center gap-4">
                <span className="font-extrabold">Status: </span>
                <div className="col-span-2 flex items-center">
                  <input
                    onChange={(e) =>
                      setUpdatedCoupon((prev) => ({
                        ...prev,
                        status: e.target.checked,
                      }))
                    }
                    type="checkbox"
                    checked={updatedCoupon.status}
                    className="mr-2"
                  />
                  <label>{updatedCoupon.status ? 'Active' : 'Inactive'}</label>
                </div>
              </pre>
            </div>

            {/* Action Buttons */}
            <div className="flex flex-col gap-4 items-center mt-4">
              {isUpdating
                ? JSON.stringify(updatedCoupon) !== JSON.stringify(coupon) && (
                    <button className="p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white opacity-50 w-full md:w-5/6">
                      Updating
                    </button>
                  )
                : JSON.stringify(updatedCoupon) !== JSON.stringify(coupon) && (
                    <button
                      onClick={() => {
                        setIsUpdating(true);
                        updateCoupon();
                      }}
                      className="p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white w-full md:w-5/6"
                    >
                      Update
                    </button>
                  )}

              {/* Delete Button */}
              <button
                onClick={() => setIsModalOpen(true)}
                className="p-2 rounded-lg bg-red-600 text-lg font-semibold text-white w-full md:w-5/6"
              >
                Delete
              </button>
            </div>
          </main>
        ) : (
          <div className="p-16 text-3xl font-extrabold">
            No User Found With Id : {id}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default SpecificCoupon;
