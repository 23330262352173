import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import EmployeeRow from './EmployeeRow';

interface EmployeesTableProps {
    role: string;
}

const EmployeesTable: React.FC<EmployeesTableProps> = ({ role }) => {
    const users = useAppSelector((state) => state.employee.results) || [];
    
    return (
        <section>
            <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
                <div className="grid grid-cols-10 text-left w-full text-base font-bold leading-6 text-blue-600">
                    <p className='p-4 col-span-3'>Phone</p>
                    <p className='p-4 col-span-3'>Name</p>
                    <p className='p-4 col-span-2'>Role</p>
                    {role === "teacher" && <p className='p-4 col-span-2'>Answer Sheets</p>}
                    {role === "doubt_solver" && <p className='p-4 col-span-2'>Doubts Solved</p>}
                </div>
                {users.map((user, index) => (
                    <EmployeeRow role={role} key={index} user={user} isEven={index % 2 === 0} />
                ))}
            </div>
        </section>
    );
};

export default EmployeesTable;