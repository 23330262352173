import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function SpecificDoubt() {
  interface doubtType {
    id: string;
    submission: {
      checked_answer_sheet: string;
      submitted_answer_sheet: string;
      test: {
        test_title: string;
        answer_key: string;
        question_paper: string;
      };
      user: {
        phone: number;
      };
    };
    user: {
      name: string;
    };
    question: string;
    answer: string;
    attached_object: string;
    doubt_status: boolean;
  }

  interface DownloadButtonProps {
    fileUrl: string;
    label: string;
  }

  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState<boolean>(true);
  const [phone, setPhone] = useState<number>();
  const [email, setEmail] = useState<string>();
  const [doubt, setDoubt] = useState<doubtType>({
    id: '',
    submission: {
      checked_answer_sheet: '',
      submitted_answer_sheet: '',
      test: {
        test_title: '',
        answer_key: '',
        question_paper: '',
      },
      user: {
        phone: 0,
      },
    },
    user: {
      name: '',
    },
    question: '',
    answer: '',
    attached_object: '',
    doubt_status: false,
  });
  const [updatedDoubt, setUpdatedDoubt] = useState<doubtType>({
    id: '',
    submission: {
      checked_answer_sheet: '',
      submitted_answer_sheet: '',
      test: {
        test_title: '',
        answer_key: '',
        question_paper: '',
      },
      user: {
        phone: 0,
      },
    },
    user: {
      name: '',
    },
    question: '',
    answer: '',
    attached_object: '',
    doubt_status: false,
  });
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.get(`admin/doubts/${id}`, config)
        .then((res) => {
          setDoubt(res.data);
          setUpdatedDoubt(res.data);
        })
        .catch((err) => {
          if (err.response.status == 403) dispatch(setAccess(''));
          if (err.response.status == 404) setStatus(false);
          console.log(err);
        });
    }
  }, []);

  async function updateDoubt(updatedDoubt: any) {
    console.log(updatedDoubt);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let attachment = '';
    if (selectedFile) {
      const response = await BaseUrl.get(
        `doubt-presigned-url/?object_key=${selectedFile.name}`,
        config
      );
      const presignedUrl = response.data.url;
      const uploadResponse = await axios.put(presignedUrl, selectedFile, {
        headers: {
          'Content-Type': selectedFile.type,
          'x-amz-acl': 'public-read',
        },
      });
      if (uploadResponse.status === 200) {
        const endIndex = presignedUrl.indexOf('?');
        const extractedString = presignedUrl.substring(61, endIndex);
        const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN;
        attachment = baseUrl + extractedString;
      } else {
        alert('File upload failed');
      }
    }
    BaseUrl.put(
      `admin/doubts/${id}/`,
      {
        answer: updatedDoubt.answer,
        attached_object: attachment || updatedDoubt.attached_object,
        doubt_status: updatedDoubt.doubt_status,
      },
      config
    ).then((res) => {
      console.log(res.data);
      setDoubt(res.data);
      setUpdatedDoubt(res.data); // Reset updated doubt with the server's response
      setSelectedFile(undefined);
      toast.success('Updated Successfully');
      setIsUpdating(false);
    });
  }

  async function downloadAttachment() {
    const fileUrl = updatedDoubt.attached_object;

    try {
      // Fetch the file from the URL
      const response = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });

      // Convert the response into a Blob
      const blob = await response.blob();

      // Create a link element, set its href to a URL created from the Blob, and trigger the download
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = fileUrl.split('/').pop() || 'downloaded_file'; // Extract the filename from the URL
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download failed:', error);
    }
  }

  const handleAttachClick = () => {
    const inpElement = document.getElementById('supportAttachment');
    inpElement?.click();
  };

  const handleFileChange = (event: any) => {
    // Handle the file selection
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  async function downloadFile(fileUrl: string) {
    try {
      const response = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });

      const blob = await response.blob();

      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = fileUrl.split('/').pop() || 'downloaded_file';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download failed:', error);
    }
  }

  const DownloadButton: React.FC<DownloadButtonProps> = ({
    fileUrl,
    label,
  }) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = async () => {
      setIsDownloading(true);
      await downloadFile(fileUrl);
      setIsDownloading(false);
    };

    return (
      <div className="flex flex-col items-left space-y-2">
        <p className="font-semibold text-center">{label}</p>
        <button
          onClick={handleDownload}
          disabled={isDownloading}
          className={`p-2 px-4 cursor-pointer text-white rounded-lg ${
            isDownloading ? 'bg-gray-400' : 'bg-green-400'
          }`}
        >
          {isDownloading ? 'Downloading...' : 'Download'}
        </button>
      </div>
    );
  };

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Doubts" />
      <div className="col-span-1 md:col-span-4 bg-[#F4F6F9] w-full min-h-screen">
        <header className="flex gap-5 justify-between px-5 md:px-11 py-6 w-full border-b border-solid border-zinc-200 flex-wrap">
          <div className="flex flex-col sm:flex-row gap-5 mx-auto sm:mx-0 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center sm:justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Doubts
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500 flex-wrap">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <a href="/users" className="text-blue-600">
                Reports
              </a>
              <span>›</span>
              <span
                className="text-blue-600 cursor-pointer"
                onClick={() => navHandler('/doubts')}
              >
                Doubt
              </span>
              <span>›</span>
              {status ? (
                <span>
                  {updatedDoubt?.submission?.test?.test_title} -{' '}
                  {updatedDoubt?.user?.name}
                </span>
              ) : (
                <span>Not Found</span>
              )}
            </nav>
          </div>
        </header>
        {status ? (
          <main className="flex flex-col p-4 md:p-8 w-full">
            <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl w-full mx-auto">
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
                <span className="font-extrabold">Submission :</span>
                <p className="col-span-2 outline-none border-zinc-300 py-2 px-2 rounded-lg">
                  {updatedDoubt.submission.test.test_title}
                </p>
              </pre>
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
                <span className="font-extrabold">User :</span>
                <p className="col-span-2 outline-none border-zinc-300 py-2 px-2 rounded-lg">
                  {updatedDoubt.user.name}
                </p>
              </pre>

              {updatedDoubt.attached_object ? (
                <pre className="w-full grid grid-cols-3 justify-left">
                  <span className="font-extrabold">Support Attachment : </span>
                  <div className="grid grid-cols-3 col-span-2 gap-2">
                    {updatedDoubt.attached_object && (
                      <DownloadButton
                        fileUrl={updatedDoubt.attached_object}
                        label="Support attachment"
                      />
                    )}
                  </div>
                </pre>
              ) : (
                <pre className="w-full grid grid-cols-1 md:grid-cols-3">
                  <span className="font-extrabold">Support Attachment :</span>
                  <p className="col-span-2 w-11/12 overflow-x-auto px-2">
                    No Attachment Provided
                  </p>
                </pre>
              )}

              {updatedDoubt.submission ? (
                <pre className="w-full grid grid-cols-3 justify-left">
                  <span className="font-extrabold">Documents : </span>
                  <div className="grid grid-cols-3 col-span-2 gap-2">
                    {updatedDoubt.submission.test.question_paper && (
                      <DownloadButton
                        fileUrl={updatedDoubt.submission.test.question_paper}
                        label="Question Paper"
                      />
                    )}
                    {updatedDoubt.submission.submitted_answer_sheet && (
                      <DownloadButton
                        fileUrl={updatedDoubt.submission.submitted_answer_sheet}
                        label="Answer Sheet"
                      />
                    )}
                    {updatedDoubt.submission.test.answer_key && (
                      <DownloadButton
                        fileUrl={updatedDoubt.submission.test.answer_key}
                        label="Standard Answer Key"
                      />
                    )}
                    {updatedDoubt.submission.checked_answer_sheet && (
                      <DownloadButton
                        fileUrl={updatedDoubt.submission.checked_answer_sheet}
                        label="Checked Answer Key"
                      />
                    )}
                  </div>
                </pre>
              ) : (
                <pre className="w-full grid grid-cols-1 md:grid-cols-3">
                  <span className="font-extrabold">Documents :</span>
                  <p className="col-span-2 w-11/12 overflow-x-auto px-2">
                    No Attachment Provided
                  </p>
                </pre>
              )}
              {selectedFile && (
                <pre className="w-full grid grid-cols-1 md:grid-cols-3">
                  <span className="font-extrabold">New Attachment :</span>
                  <p className="col-span-2 w-11/12 overflow-x-auto">
                    {selectedFile.name}
                  </p>
                </pre>
              )}
              <pre className="w-full grid grid-cols-1 md:grid-cols-3">
                <span className="font-extrabold">Question :</span>
                <p className="col-span-2 w-full break-words whitespace-normal px-2">
                  {updatedDoubt.question}
                </p>
              </pre>
              <pre className="w-full grid grid-cols-1 md:grid-cols-3">
                <span className="font-extrabold">Answer :</span>
                <textarea
                  onChange={(e) =>
                    setUpdatedDoubt((prev) => ({
                      ...prev,
                      answer: e.target.value,
                    }))
                  }
                  className="col-span-2 resize-none h-[15vh] outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg"
                  value={updatedDoubt.answer}
                />
              </pre>
              <div className="flex justify-between">
                {/* Update Button */}
                {isUpdating
                  ? (JSON.stringify(doubt) !== JSON.stringify(updatedDoubt) ||
                      selectedFile) && (
                      <button className="p-2 mx-auto rounded-lg bg-blue-600 text-lg font-semibold text-white opacity-50">
                        Updating
                      </button>
                    )
                  : (JSON.stringify(doubt) !== JSON.stringify(updatedDoubt) ||
                      selectedFile) && (
                      <button
                        onClick={() => {
                          if (!updatedDoubt.doubt_status) {
                            setUpdatedDoubt((prev) => ({
                              ...prev,
                              doubt_status: true, // Mark as resolved on first update
                            }));
                            updateDoubt({
                              ...updatedDoubt,
                              doubt_status: true,
                            });
                          } else {
                            updateDoubt(updatedDoubt);
                          }
                          setIsUpdating(true);
                        }}
                        className="p-2 mx-auto w-1/4 rounded-lg bg-blue-600 text-lg font-semibold text-white"
                      >
                        {updatedDoubt.doubt_status
                          ? 'Update'
                          : 'Mark as Resolved and Update'}
                      </button>
                    )}
              </div>
            </div>
          </main>
        ) : (
          <div className="p-16 text-3xl font-extrabold">
            No User Found With Id : {id}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default SpecificDoubt;
