import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SpecificEmployee() {

    interface employeeType {
        id: string;
        phone_number: string;
        first_name: string;
        last_name: string;
        role: string;
        answer_sheets_checked: number;
        doubts_solved: number
    }
    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const { id } = useParams();
    const [status, setStatus] = useState<boolean>(true)
    const [employee, setEmployee] = useState<employeeType>({
        id: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        role: "",
        answer_sheets_checked: 0,
        doubts_solved: 0
    })
    const [updatedEmployee, setUpdatedEmployee] = useState<employeeType>({
        id: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        role: "",
        answer_sheets_checked: 0,
        doubts_solved: 0
    })
    const roles = ["admin","teacher","doubt_solver"]
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/employee/${id}`, config)
                .then((res) => {
                    setEmployee(res.data)
                    setUpdatedEmployee(res.data)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    if (err.response.status == 404)
                        setStatus(false)
                    console.log(err)
                })
        }
    }, [])

    const validatePhoneNumber = (value: string) => {
        const phonePattern = /^[0-9]{10}$/; // Matches a 10-digit number
        return phonePattern.test(value);
    };    

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setUpdatedEmployee(prev => ({ ...prev, phone_number: value }));
    
        if (validatePhoneNumber(value)) {
            setError('');
        } else {
            setError('Please enter a valid 10-digit phone number');
        }
    };
    

    function updateEmployee() {
        if (error) {
            toast.error('Please fix the errors before updating');
            return;
        }
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        BaseUrl.patch(`admin/employee/${id}/`, {
            phone_number: updatedEmployee.phone_number,
            first_name: updatedEmployee.first_name || "-",
            last_name: updatedEmployee.last_name,
            role: updatedEmployee.role,
            answer_sheets_checked: updatedEmployee.answer_sheets_checked,
            doubts_solved: updatedEmployee.doubts_solved
        }, config)
            .then((res) => {
                setEmployee(res.data)
                setUpdatedEmployee(res.data)
                toast.success("Updated Succesfully")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
    }

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Employees' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Employees
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <a href="/users" className="text-blue-600">Employees</a>
                            <span>›</span>
                            <span className='text-blue-600 cursor-pointer' onClick={() => navHandler("/employees")}>Employee</span>
                            <span>›</span>
                            {status ? <span>{updatedEmployee.phone_number}</span> : <span>Not Found</span>}
                        </nav>
                    </div>
                </header>
                {status ? <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-4/5 mx-auto">
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Phone : </span>
                            <input onChange={handlePhoneChange} className='col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={updatedEmployee.phone_number} />
                        </pre>
                        {error && <p className="col-span-2 text-red-500">{error}</p>}
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>First Name : </span>
                            <input onChange={(e)=>setUpdatedEmployee(prev => ({...prev,first_name:e.target.value}))} className='col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={updatedEmployee.first_name} />
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Last Name : </span>
                            <input onChange={(e)=>setUpdatedEmployee(prev => ({...prev,last_name:e.target.value}))} className='col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={updatedEmployee.last_name} />
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Role : </span>
                            <select
                            onChange={(e)=>{
                                setUpdatedEmployee((prev)=>({...prev,role:e.target.value}))
                            }} className='col-span-2 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {roles.map((role, index) => (
                                    role && (
                                        <option className='w-full' key={index} value={role} selected={role == updatedEmployee.role}>
                                            {role}
                                        </option>
                                    )
                                ))}
                            </select>
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Answer sheets checked : </span>
                            <input type='number' min={0} onChange={(e)=>setUpdatedEmployee(prev => ({...prev,answer_sheets_checked:parseInt(e.target.value)}))} className='outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={updatedEmployee.answer_sheets_checked || 0} />
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Doubts solved : </span>
                            <input type='number' min={0} onChange={(e)=>setUpdatedEmployee(prev => ({...prev,doubts_solved:parseInt(e.target.value)}))} className='outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={updatedEmployee.doubts_solved || 0} />
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span onClick={()=>{navHandler(`/employee/reset/${updatedEmployee.id}`)}} className='cursor-pointer text-blue-500'>Reset password for this employee?</span>
                        </pre>
                        {(JSON.stringify(employee) != JSON.stringify(updatedEmployee)) && <button onClick={updateEmployee} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white'>Update</button>}
                    </div>
                </main> : <div className='p-16 text-3xl font-extrabold'>No User Found With Id : {id}</div>}
            </div>
            <ToastContainer />
        </div>
    );
}

export default SpecificEmployee;
