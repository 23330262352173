import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SpecificEmployee() {
  interface employeeType {
    id: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    role: string;
    answer_sheets_checked: number;
    doubts_solved: number;
  }

  interface CourseTypes {
    id: number;
    title: string;
  }

  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const role = useAppSelector((state: RootState) => state.auth.role);
  const [courseTypes, setCourseTypes] = useState<CourseTypes[]>([]);
  // const [subjects, setSubjects] = useState<SubjectTypes[]>([]);
  // const [filteredSubjects, setFilteredSubjects] = useState<SubjectTypes[]>([]);
  // const [selectedCourseType, setSelectedCourseType] = useState<CourseTypes>();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState<boolean>(true);

  const [employee, setEmployee] = useState<employeeType>({
    id: '',
    phone_number: '',
    first_name: '',
    last_name: '',
    role: '',
    answer_sheets_checked: 0,
    doubts_solved: 0,
  });

  const [updatedEmployee, setUpdatedEmployee] = useState<employeeType>({
    id: '',
    phone_number: '',
    first_name: '',
    last_name: '',
    role: '',
    answer_sheets_checked: 0,
    doubts_solved: 0,
  });

  const roles = ['admin', 'teacher', 'doubt_solver'];
  const [error, setError] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      fetchEmployeeData();
      fetchCourseTypes();
      fetchSubjects();
    }
  }, [id, token]);

  function fetchEmployeeData() {
    const config = { headers: { Authorization: `Bearer ${token}` } };

    BaseUrl.get(`admin/employee/${id}`, config)
      .then((res) => {
        const employeeData = res.data;
        setEmployee(employeeData);
        setUpdatedEmployee(employeeData);
      })
      .catch(handleApiError);
  }

  console.log(employee);

  function fetchCourseTypes() {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    BaseUrl.get(`admin/non-paginated-course-type/`, config)
      .then((res) => setCourseTypes(res.data))
      .catch(handleApiError);
  }

  function fetchSubjects() {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    BaseUrl.get(`admin/non-paginated-subject`, config)
      .then((res) => res.data)
      .catch(handleApiError);
  }

  // useEffect(() => {
  //   setSelectedCourseType(
  //     courseTypes.find(
  //       (type) => type?.id === updatedEmployee.assigned_subject?.course_type
  //     )
  //   );
  // }, [updatedEmployee.assigned_subject]);

  // useEffect(() => {
  //   if (selectedCourseType) {
  //     const filtered = subjects.filter(
  //       (subject) => subject?.course_type === selectedCourseType?.id
  //     );
  //     setFilteredSubjects(filtered);

  //     if (
  //       !filtered.find(
  //         (subject) => subject?.id === updatedEmployee.assigned_subject?.id
  //       )
  //     ) {
  //       setUpdatedEmployee((prev) => ({
  //         ...prev,
  //         assigned_subject: {
  //           id: filtered.length > 0 ? filtered[0]?.id : '',
  //           title: filtered.length > 0 ? filtered[0].title : '',
  //           course_type: selectedCourseType?.id,
  //         },
  //       }));
  //     }
  //   } else {
  //     setFilteredSubjects(subjects);
  //   }
  // }, [selectedCourseType, subjects]);

  const validatePhoneNumber = (value: string) => {
    const phonePattern = /^[0-9]{10}$/; // Matches a 10-digit number
    return phonePattern.test(value);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUpdatedEmployee((prev) => ({ ...prev, phone_number: value }));

    if (validatePhoneNumber(value)) {
      setError('');
    } else {
      setError('Please enter a valid 10-digit phone number');
    }
  };

  const updateEmployee = () => {
    updateRoleBasedEmployee(employee.role);
  };

  function updateRoleBasedEmployee(role: string) {
    const apiEndpoint =
      role === 'teacher'
        ? `admin/teacher/${id}/`
        : role === 'doubt_solver'
        ? `admin/doubt-solver/${id}/`
        : `admin/employee/${id}/`;

    const payload = {
      phone_number: updatedEmployee.phone_number,
      first_name: updatedEmployee.first_name,
      last_name: updatedEmployee.last_name,
    };

    BaseUrl.put(apiEndpoint, payload, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setEmployee(res.data);
        setUpdatedEmployee(res.data);
        toast.success(
          `${role.charAt(0).toUpperCase() + role.slice(1)} Updated Successfully`
        );
      })
      .catch((err) => handleApiError(err));
  }

  function handleApiError(err: any) {
    if (err.response) {
      console.log('Error status:', err.response.status);
      console.log('Error data:', err.response.data);
      if (err.response.status === 403) {
        dispatch(setAccess(''));
      }
    } else {
      console.log('Unknown error:', err);
    }
  }

  const deleteEmployee = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    BaseUrl.delete(`admin/employee/${id}/`, config)
      .then(() => {
        toast.success('Employee deleted successfully');
        navHandler('/employees');
      })
      .catch((err) => {
        if (err.response.status === 403) dispatch(setAccess(''));
        console.log(err);
      });
  };
  console.log(employee, updatedEmployee);
  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Employees" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex flex-wrap gap-5 justify-between px-4 md:px-11 py-6 w-full border-b border-solid border-zinc-200">
          <div className="flex flex-col sm:flex-row mx-auto sm:mx-0 gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center sm: text-xl md:text-2xl py-1.5 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Employees
            </h1>
            <nav className="flex gap-2.5 my-auto text-sm md:text-base text-gray-500">
              <a href="/" className="text-blue-600">
                Home
              </a>
              <span>›</span>
              <a href="/users" className="text-blue-600">
                Employees
              </a>
              <span>›</span>
              <span
                className="text-blue-600 cursor-pointer"
                onClick={() => navHandler('/employees')}
              >
                Employee
              </span>
              <span>›</span>
              {status ? (
                <span>{updatedEmployee.phone_number}</span>
              ) : (
                <span>Not Found</span>
              )}
            </nav>
          </div>
        </header>
        {status ? (
          <main className="flex flex-col px-4 md:px-8 w-full max-md:mt-10">
            <div className="flex flex-col gap-4 p-4 md:p-8 mt-4 bg-white rounded-xl shadow-xl w-full md:w-4/5 mx-auto">
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
                <span className="font-extrabold">Phone : </span>
                <input
                  onChange={handlePhoneChange}
                  className="col-span-2 outline-none border border-zinc-300 py-2 px-4 md:px-8 rounded-lg"
                  value={updatedEmployee.phone_number}
                />
              </pre>
              {error && <p className="text-red-500">{error}</p>}
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
                <span className="font-extrabold">First Name : </span>
                <input
                  onChange={(e) =>
                    setUpdatedEmployee((prev) => ({
                      ...prev,
                      first_name: e.target.value,
                    }))
                  }
                  className="col-span-2 outline-none border border-zinc-300 py-2 px-4 md:px-8 rounded-lg"
                  value={updatedEmployee.first_name}
                />
              </pre>
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
                <span className="font-extrabold">Last Name : </span>
                <input
                  onChange={(e) =>
                    setUpdatedEmployee((prev) => ({
                      ...prev,
                      last_name: e.target.value,
                    }))
                  }
                  className="col-span-2 outline-none border border-zinc-300 py-2 px-4 md:px-8 rounded-lg"
                  value={updatedEmployee.last_name}
                />
              </pre>
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
                <span className="font-extrabold">Role : </span>
                <select
                  onChange={(e) =>
                    setUpdatedEmployee((prev) => ({
                      ...prev,
                      role: e.target.value,
                    }))
                  }
                  className="col-span-2 outline-none border border-zinc-300 py-2 px-4 md:px-8 rounded-lg"
                  value={updatedEmployee.role}
                >
                  {roles.map((role, index) => (
                    <option key={index} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </pre>
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
                <span className="font-extrabold">Answer Sheets Checked : </span>
                <input
                  onChange={(e) =>
                    setUpdatedEmployee((prev) => ({
                      ...prev,
                      answer_sheets_checked: parseInt(e.target.value),
                    }))
                  }
                  className="col-span-2 outline-none border border-zinc-300 py-2 px-4 md:px-8 rounded-lg"
                  value={updatedEmployee.answer_sheets_checked}
                />
              </pre>
              {employee.role === 'doubt_solver' && (
                <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
                  <span className="font-extrabold">Doubts Solved : </span>
                  <input
                    onChange={(e) =>
                      setUpdatedEmployee((prev) => ({
                        ...prev,
                        doubts_solved: parseInt(e.target.value),
                      }))
                    }
                    className="col-span-2 outline-none border border-zinc-300 py-2 px-4 md:px-8 rounded-lg"
                    value={updatedEmployee.doubts_solved}
                  />
                </pre>
              )}

              <span
                onClick={() =>
                  navHandler(`/employee/reset/${updatedEmployee.id}`)
                }
                className="cursor-pointer text-blue-500"
              >
                Reset password for this employee?
              </span>
              {JSON.stringify(employee) !== JSON.stringify(updatedEmployee) && (
                <button
                  onClick={updateEmployee}
                  className="p-2 w-1/4 mx-auto rounded-lg bg-blue-600 text-lg font-semibold text-white"
                >
                  Update
                </button>
              )}

              {/* {employee.role !== 'super_admin' && (
                <button
                  onClick={() => setShowDeleteModal(true)}
                  className="p-2 w-1/4 mx-auto rounded-lg bg-red-600 text-lg font-semibold text-white mt-4"
                >
                  Delete Employee
                </button>
              )} */}
            </div>
          </main>
        ) : (
          <div className="w-full h-[90vh] flex justify-center items-center">
            <span className="text-2xl md:text-4xl text-gray-400">
              Employee Not Found
            </span>
          </div>
        )}
      </div>
      {/* Confirmation Modal */}
      {/* {showDeleteModal && employee.role !== 'super_admin' && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">
              Are you sure you want to delete this employee?
            </h2>
            <div className="flex justify-end">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="mr-4 px-4 py-2 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={deleteEmployee}
                className="px-4 py-2 bg-red-600 text-white rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )} */}
      <ToastContainer />
    </div>
  );
}

export default SpecificEmployee;
