import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import TestSeriesRow from './TestSeriesRow';

const TestSeriesTable: React.FC = () => {
    const testseries = useAppSelector((state) => state.testSeries.results) || []
    return (
        <section>
            <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
                <div className="grid grid-cols-8 text-left w-full text-base font-bold leading-6 text-blue-600">
                    <p className='p-4'>Id</p>
                    <p className='p-4 col-span-3'>Title</p>
                    <p className='p-4 col-span-2'>Discounted Price</p>
                    <p className='p-4 col-span-2'>Mrp Price</p>
                    {/* <p className='p-4 col-span-3'>Email</p> */}
                </div>
                {testseries.map((series, index) => (
                    <TestSeriesRow key={index} testSeries={series} isEven={index % 2 === 0} />
                ))}
            </div>
        </section>
    );
};

export default TestSeriesTable;