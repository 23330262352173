import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function SpecificDoubt() {

    interface doubtType {
        id: string;
        submission: {
            test: {
                test_title: string
            },
            user: {
                phone: number
            }
        };
        user: {
            phone: number
        };
        question: string;
        answer: string;
        attached_object: string;
        doubt_status : boolean
    }

    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const { id } = useParams();
    const [status, setStatus] = useState<boolean>(true)
    const [phone, setPhone] = useState<number>()
    const [email, setEmail] = useState<string>()
    const [doubt, setDoubt] = useState<doubtType>({
        id: "",
        submission: {
            test: {
                test_title: ""
            },
            user: {
                phone: 0
            }
        },
        user: {
            phone: 0
        },
        question: "",
        answer: "",
        attached_object : "",
        doubt_status : false
    })
    const [updatedDoubt, setUpdatedDoubt] = useState<doubtType>({
        id: "",
        submission: {
            test: {
                test_title: ""
            },
            user: {
                phone: 0
            }
        },
        user: {
            phone: 0
        },
        question: "",
        answer: "",
        attached_object : "",
        doubt_status : false
    })
    const [selectedFile, setSelectedFile] = useState<File>()
    const [isUpdating, setIsUpdating] = useState<boolean>(false)

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/doubts/${id}`, config)
                .then((res) => {
                    setDoubt(res.data)
                    setUpdatedDoubt(res.data)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    if (err.response.status == 404)
                        setStatus(false)
                    console.log(err)
                })
        }
    }, [])

    async function updateDoubt() {
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        let attachment = ""
        if (selectedFile) {
            const response = await BaseUrl.get(`doubt-presigned-url/?object_key=${selectedFile.name}`, config)
            const presignedUrl = response.data.url
            const uploadResponse = await axios.put(presignedUrl, selectedFile, {
                headers: {
                    'Content-Type': selectedFile.type,
                    'x-amz-acl': 'public-read',
                },
            });
            if (uploadResponse.status === 200) {
                const endIndex = presignedUrl.indexOf('?');
                const extractedString = presignedUrl.substring(61, endIndex);
                const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN
                attachment = baseUrl + extractedString
            } else {
                alert("File upload failed");
            }
        }
        BaseUrl.patch(`admin/doubts/${id}/`, {
            answer : updatedDoubt.answer,
            attached_object : attachment || updatedDoubt.attached_object,
            doubt_status : updatedDoubt.doubt_status
        }, config)
            .then((res) => {
                setDoubt(res.data)
                setUpdatedDoubt(res.data)
                setSelectedFile(undefined)
                toast.success("Updated Succesfully")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
        setIsUpdating(false)
    }

    async function downloadAttachment() {
        const fileUrl = updatedDoubt.attached_object;

        try {
            // Fetch the file from the URL
            const response = await fetch(fileUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            });

            // Convert the response into a Blob
            const blob = await response.blob();

            // Create a link element, set its href to a URL created from the Blob, and trigger the download
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileUrl.split('/').pop() || "downloaded_file"; // Extract the filename from the URL
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Download failed:', error);
        }
    }

    const handleAttachClick = () => {
        const inpElement = document.getElementById("supportAttachment")
        inpElement?.click()
    };

    const handleFileChange = (event: any) => {
        // Handle the file selection
        const file = event.target.files[0];
        setSelectedFile(file)
    };

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Doubts' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Doubts
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <a href="/users" className="text-blue-600">Reports</a>
                            <span>›</span>
                            <span className='text-blue-600 cursor-pointer' onClick={() => navHandler("/doubts")}>Doubt</span>
                            <span>›</span>
                            {status ? <span>{updatedDoubt?.submission?.test?.test_title} - {updatedDoubt?.user?.phone}</span> : <span>Not Found</span>}
                        </nav>
                    </div>
                </header>
                {status ? <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-4/5 mx-auto">
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Submission : </span>
                            <p className='col-span-2 outline-none border-zinc-300 py-2 px-2 rounded-lg'>{updatedDoubt.submission.test.test_title}</p>
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>User : </span>
                            <p className='col-span-2 w-1/2 outline-none border-zinc-300 py-2 px-2 rounded-lg'>{updatedDoubt.user.phone}</p>
                        </pre>
                        {updatedDoubt.attached_object ? <pre className='w-full grid grid-cols-3'>
                            <span className='font-extrabold'>Support attachment : </span>
                            <div className='flex flex-col items-left space-y-2'>
                                <button onClick={downloadAttachment} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg' >Download</button>
                            </div>
                        </pre> : <pre className='w-full grid grid-cols-3'>
                            <span className='font-extrabold'>Support Attachment : </span>
                            <p className='col-span-2 w-11/12 overflow-x-auto px-2'>No Attachment Provided</p>
                        </pre>}
                        {selectedFile && <pre className='w-full grid grid-cols-3'>
                            <span className='font-extrabold'>New Attachment : </span>
                            <p className='col-span-2 w-11/12 overflow-x-auto'>{selectedFile.name}</p>
                        </pre>}
                        <pre className='w-full grid grid-cols-3 justify-left'>
                            <span className='font-extrabold'>Check Status : </span>
                            <div className='flex justify-left'>
                                <input onChange={(e) => setUpdatedDoubt((prev) => ({ ...prev, doubt_status : e.target.checked }))} type='checkbox' checked={updatedDoubt.doubt_status} />
                            </div>
                        </pre>
                        <pre className='w-full grid grid-cols-3'>
                            <span className='font-extrabold'>Question : </span>
                            <p className='col-span-2 w-3/4 break-words whitespace-normal px-2'>{updatedDoubt.question}</p>
                        </pre>
                        <pre className='w-full grid grid-cols-3'>
                            <span className='font-extrabold'>Answer : </span>
                            <textarea onChange={(e)=>setUpdatedDoubt((prev)=>({...prev,answer:e.target.value}))} className='col-span-2 resize-none h-[15vh] outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={updatedDoubt.answer} />
                        </pre>
                        {/* <pre className='w-full grid grid-cols-3'>
                            <span className='font-extrabold'>Answer : </span>
                            <div className='grid grid-cols-12 col-span-2'>
                                <textarea onChange={(e)=>setUpdatedDoubt((prev)=>({...prev,answer:e.target.value}))} className='col-span-11 resize-none h-[15vh] outline-none border border-1 border-zinc-300 border-r-0 py-2 px-8 rounded-l-lg' value={updatedDoubt.answer} />
                                <div className='h-[15vh] outline-none border border-1 border-zinc-300 border-l-0 py-2 rounded-r-lg flex justify-center items-center cursor-pointer'>
                                    <div className='flex justify-center'>
                                        <input type='file' accept='application/pdf'
                                            className='hidden'
                                            id='supportAttachment'
                                            onChange={handleFileChange} />
                                        <img onClick={handleAttachClick} src='/attach.svg' alt='attach' />
                                    </div>
                                </div>
                            </div>
                        </pre> */}
                        {isUpdating ? ((JSON.stringify(doubt) != JSON.stringify(updatedDoubt)) || selectedFile) && <button className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white opacity-50'>Updating</button> : ((JSON.stringify(doubt) != JSON.stringify(updatedDoubt)) || selectedFile) && <button onClick={()=>{setIsUpdating(true);updateDoubt()}} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white'>Update</button>}
                    </div>
                </main> : <div className='p-16 text-3xl font-extrabold'>No User Found With Id : {id}</div>}
            </div>
            <ToastContainer />
        </div>
    );
}

export default SpecificDoubt;
