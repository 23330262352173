import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UsersState {
    count: number;
    previous: string | null;
    next: string | null;
    current: number;
    results: any[]; 
}

const initialState: UsersState = {
    count: 0,
    previous: null,
    next: null,
    results: [],
    current: 1
};

export const usersSlice = createSlice({
    name: 'users', 
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<UsersState>) => {
            state.count = action.payload.count;
            state.previous = action.payload.previous;
            state.next = action.payload.next;
            state.results = action.payload.results;
            state.current = action.payload.current || 1;
        },
        setCurrentUserPage: (state, action: PayloadAction<number>) => {
            state.current = action.payload;
        },
    },
});

export const { setUsers, setCurrentUserPage } = usersSlice.actions;

export default usersSlice.reducer;