import React from 'react';
import { useNavigate } from 'react-router-dom';

interface User {
    id: string;
    phone: string;
    name: string;
    gender: string;
    email: string;
}

interface UserRowProps {
    user: User;
    isEven: boolean;
}

const UserRow: React.FC<UserRowProps> = ({ user, isEven }) => {
    const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
    const navHandler = useNavigate()

    return (
        <div onClick={()=>(navHandler(`/user/${user.id}`))} className={`grid grid-cols-10 gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto`}>
            <div className="justify-center items-start p-4 text-base font-bold leading-6 text-blue-600 whitespace-nowrap border-t border-solid border-zinc-200 max-md:pr-5">
                {user.id}
            </div>
            <div className="col-span-2 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {user.phone || '-'}
            </div>
            <div className="col-span-2 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {user.name || '-'}
            </div>
            <div className="col-span-2 justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {user.gender || '-'}
            </div>
            <div className="col-span-3 justify-center items-start p-4 text-base leading-6 border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {user.email || '-'}
            </div>
        </div>
    );
};

export default UserRow;