import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import OrdersTable from './OrdersTable';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setCurrent, setOrders } from '../../redux/slices/orders';
import { setAccess } from '../../redux/slices/auth';

function Orders() {
  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const prev = useAppSelector((state: RootState) => state.orders.previous);
  const next = useAppSelector((state: RootState) => state.orders.next);
  const current = useAppSelector((state: RootState) => state.orders.current);
  const dispatch = useAppDispatch();
  const [number, setNumber] = useState('');
  const [debouncedNumber, setDebouncedNumber] = useState(number);
  const [orderStatus, setOrderStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to manage loading

  useEffect(() => {
    if (!token) {
      navHandler("/login");
    } else {
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };
      
      setIsLoading(true); // Start loading
      BaseUrl.get(`filter-order/?page=${current}&user_phone=${number}&order_status=${orderStatus}`, config)
        .then((res) => {
          const curr = res.data.current;
          const modifiedData = {
            ...(res.data),
            current: curr
          };
          dispatch(setOrders(modifiedData));
        })
        .catch((err) => {
          if (err.response.status === 403) {
            dispatch(setAccess(""));
          }
          if (err.response.status === 404) {
            dispatch(setCurrent(1));
          }
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false); // Stop loading
        });
    }
  }, [current, orderStatus, debouncedNumber]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedNumber(number);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [number]);

  return (
    <div className="text-sm grid grid-cols-5">
      <Sidebar activeLabel='Orders' />
      <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Orders
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">Home</a>
              <span>›</span>
              <a href="/orders" className="text-blue-600">Orders</a>
              <span>›</span>
              <span>Orders</span>
            </nav>
          </div>
        </header>
        <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="my-6">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-700">Filters</p>
              <button
                onClick={() => {
                  setNumber('');
                  setOrderStatus('');
                }}
                className="px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Clear All Filters
              </button>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-3 gap-6">
              <div>
                <label htmlFor="number" className="block text-sm font-medium text-gray-700 mb-2">Number:</label>
                <input
                  type="number"
                  id="number"
                  name="number"
                  placeholder="Enter Number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-2">Order Status:</label>
                <select
                  id="role"
                  name="role"
                  value={orderStatus}
                  onChange={(e) => setOrderStatus(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">----</option>
                  <option value="Completed">Completed</option>
                  <option value="Canceled">Cancelled</option>
                  <option value="Processing">Processing</option>
                </select>
              </div>
            </div>
          </div>
          <OrdersTable />
        </main>
        <div className='flex gap-2 w-full justify-center items-center m-4'>
          {prev && (
            <button 
              onClick={() => dispatch(setCurrent(current - 1))}
              disabled={isLoading}
              className={`px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            >
              {"<"}
            </button>
          )}
          <div className='px-2 py-1 rounded-lg border border-2 border-blue-400 text-xl text-white font-semibold bg-blue-600 cursor-pointer'>{current}</div>
          {next && (
            <button 
              onClick={() => dispatch(setCurrent(current + 1))}
              disabled={isLoading}
              className={`px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            >
              {">"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Orders;