import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import tests from '../../redux/slices/tests';

function SpecificTestSeries() {
  interface attemptInterface {
    id: number;
    month?: string;
    year?: string;
  }

  interface courseTypeInterface {
    id?: number;
    title?: string;
    attempts?: Array<attemptInterface>;
  }

  interface testSeriesTypeInterface {
    id?: number;
    title?: string;
  }

  interface testSeriesInterface {
    discounted_price: number;
    mrp_price: number;
    title: string;
    description: string;
    course_type: courseTypeInterface;
    status: boolean;
    test_series_type: testSeriesTypeInterface;
    test_series_attempts: attemptInterface;
    valid_from: string;
    valid_till: string;
    fees_structure: string;
    syllabus: string;
  }

  const defaultAttempt: attemptInterface = {
    id: 0, // or any default value you prefer
    month: '',
    year: '',
  };

  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState<boolean>(true);
  const [testSeries, setTestSeries] = useState<testSeriesInterface>({
    discounted_price: 0,
    mrp_price: 0,
    title: '',
    description: '',
    course_type: {},
    status: false,
    test_series_type: {},
    test_series_attempts: defaultAttempt,
    valid_from: '',
    valid_till: '',
    fees_structure: '',
    syllabus: '',
  });
  const [testSeriesNew, setTestSeriesNew] = useState<testSeriesInterface>({
    discounted_price: 0,
    mrp_price: 0,
    title: '',
    description: '',
    course_type: {},
    status: false,
    test_series_type: {},
    test_series_attempts: defaultAttempt,
    valid_from: '',
    valid_till: '',
    fees_structure: '',
    syllabus: '',
  });
  const [courseType, setCourseType] = useState<courseTypeInterface[]>([]);
  const [courseTypeId, setCourseTypeId] = useState<number>();
  const [testSeriesType, setTestSeriesType] = useState<courseTypeInterface[]>(
    []
  );
  const [testSeriesTypeId, setTestSeriesTypeId] = useState<number>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedSyllabus, setSelectedSyllabus] = useState<File>();
  const [isListVisible, setIsListVisible] = useState(false);
  const [orgAttempts, setOrgAttempts] = useState<number[]>([]);
  const [selectedAttempts, setSelectedAttempts] = useState<number[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [feesUploadProgress, setFeesUploadProgress] = useState(0);
  const [isFeesUploading, setIsFeesUploading] = useState(false);
  const [syllabusUploadProgress, setSyllabusUploadProgress] = useState(0);
  const [isSyllabusUploading, setIsSyllabusUploading] = useState(false);

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  const handleCheckboxChange = (
    event: { target: { checked: any } },
    attemptId: number
  ) => {
    if (event.target.checked) {
      setSelectedAttempts([...selectedAttempts, attemptId]);
    } else {
      setSelectedAttempts(selectedAttempts.filter((id) => id !== attemptId));
    }
  };

  const removeSelectedAttempt = (attemptId: number) => {
    setSelectedAttempts(selectedAttempts.filter((id) => id !== attemptId));
  };

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.get(`admin/test-series/${id}`, config)
        .then((res) => {
          setTestSeries(res.data);
          setTestSeriesNew(res.data);
          setCourseTypeId(res.data.course_type?.id);
        })
        .catch((err) => {
          if (err.response?.status == 403) dispatch(setAccess(''));
          if (err.response?.status == 404) setStatus(false);
          console.log(err);
        });
      BaseUrl.get(`admin/non-paginated-course-type/`, config)
        .then((res) => {
          setCourseType(res.data);
        })
        .catch((err) => {
          if (err.response?.status == 403) dispatch(setAccess(''));
          if (err.response?.status == 404) setStatus(false);
          console.log(err);
        });
      BaseUrl.get(`admin/non-paginated-test-series-type/`, config)
        .then((res) => {
          setTestSeriesType(res.data);
        })
        .catch((err) => {
          if (err.response?.status == 403) dispatch(setAccess(''));
          if (err.response?.status == 404) setStatus(false);
          console.log(err);
        });
    }
  }, []);

  async function updateTestSeries() {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    let feesStructure = '';
    let syllabus = '';

    if (selectedFile) {
      try {
        const response = await BaseUrl.get(
          `fees-structure-presigned-url/?object_key=${selectedFile.name}`,
          config
        );
        const presignedUrl = response.data.url;
        setIsFeesUploading(true);
        const uploadResponse = await axios.put(presignedUrl, selectedFile, {
          headers: {
            'Content-Type': selectedFile.type,
            'x-amz-acl': 'public-read',
          },
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total || 1;
            const progress = Math.round((progressEvent.loaded * 100) / total);
            setFeesUploadProgress(progress); // Use the correct progress state
          },
        });

        if (uploadResponse?.status === 200) {
          const endIndex = presignedUrl.indexOf('?');
          const extractedString = presignedUrl.substring(61, endIndex);
          const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN;
          feesStructure = baseUrl + extractedString;
        } else {
          alert('Fees structure file upload failed');
        }
      } catch (err) {
        console.error('Error uploading fees structure:', err);
      } finally {
        setFeesUploadProgress(0); // Reset progress after completion
        setIsFeesUploading(false); // Hide the progress bar when done
      }
    }

    if (selectedSyllabus) {
      try {
        const response = await BaseUrl.get(
          `syllabus-presigned-url/?object_key=${selectedSyllabus.name}`,
          config
        );
        setIsSyllabusUploading(true);
        const presignedUrl = response.data.url;
        const uploadResponse = await axios.put(presignedUrl, selectedSyllabus, {
          headers: {
            'Content-Type': selectedSyllabus.type,
            'x-amz-acl': 'public-read',
          },
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total || 1;
            const progress = Math.round((progressEvent.loaded * 100) / total);
            setSyllabusUploadProgress(progress); // Use correct progress state
          },
        });

        if (uploadResponse.status === 200) {
          const endIndex = presignedUrl.indexOf('?');
          const extractedString = presignedUrl.substring(61, endIndex);
          const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN;
          syllabus = baseUrl + extractedString;
        } else {
          alert('Syllabus file upload failed');
        }
      } catch (err) {
        console.error('Error uploading syllabus:', err);
      } finally {
        setSyllabusUploadProgress(0); // Reset progress after completion
        setIsSyllabusUploading(false); // Hide the progress bar when done
      }
    }

    BaseUrl.put(
      `admin/test-series/${id}/`,
      {
        course_type_id: courseTypeId,
        test_series_type_id: testSeriesTypeId,
        title: testSeriesNew.title,
        valid_from: testSeriesNew.valid_from,
        valid_till: testSeriesNew.valid_till,
        description: testSeriesNew.description,
        discounted_price: testSeriesNew.discounted_price,
        mrp_price: testSeriesNew.mrp_price,
        status: testSeriesNew.status,
        test_series_attempts_ids: selectedAttempts[0] || null,
        fees_structure: feesStructure || testSeriesNew?.fees_structure,
        syllabus: syllabus || testSeriesNew?.syllabus,
      },
      config
    )
      .then((res) => {
        setTestSeries(res.data);
        setTestSeriesNew(res.data);
        console.log(res.data.test_series_type.id);
        console.log(testSeriesNew.course_type.id);
        toast.success('Updated Succesfully');
      })
      .catch((err) => {
        if (err.response) {
          console.log('Error status:', err.response.status);
          console.log('Error data:', err.response.data);
          if (err.response?.status === 403) {
            dispatch(setAccess(''));
          }
        } else {
          console.log('Unknown error:', err);
        }
      });
  }

  function deleteTestSeries() {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    BaseUrl.delete(`admin/test-series/${id}/`, config)
      .then((res) => {
        toast.success('Deleted Succesfully');
        navHandler('/test-series');
      })
      .catch((err) => {
        if (err.response?.status == 403) dispatch(setAccess(''));
        console.log(err);
      });
    setIsModalOpen(false);
  }

  useEffect(() => {
    if (testSeries.test_series_attempts) {
      const attemptId = testSeries.test_series_attempts.id;
      setSelectedAttempts([attemptId]);
      setOrgAttempts([attemptId]);
    } else {
      setSelectedAttempts([]);
      setOrgAttempts([]);
    }
  }, [testSeries.test_series_attempts]);

  // console.log(selectedAttempts);
  const formatDateForInput = (dateString: string) => {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const convertToBackendFormat = (localDateTime: string) => {
    const date = new Date(localDateTime);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`; // Return only the date part
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSyllabusChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedSyllabus(file);
  };

  return (
    <div className="text-sm grid grid-cols-5">
      <Sidebar activeLabel="Test series" />
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-gray-800 mb-6">
              Are you sure you want to delete this Test Series?
            </h2>
            <div className="flex justify-end gap-4">
              <button
                onClick={deleteTestSeries}
                className="px-6 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 transition duration-200"
              >
                Yes
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-6 py-2 bg-gray-300 text-gray-800 font-semibold rounded-md hover:bg-gray-400 transition duration-200"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Test Series
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">Testseries</p>
              <span>›</span>
              <span
                className="text-blue-600 cursor-pointer"
                onClick={() => navHandler('/test-series')}
              >
                Test series -
              </span>
              {status ? (
                <span>{testSeries.title}</span>
              ) : (
                <span>Not Found</span>
              )}
            </nav>
          </div>
        </header>
        {status ? (
          <main className="grid grid-cols-4 flex flex-col items-start p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="col-span-3 flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-11/12 mx-auto">
              <pre className="w-full grid grid-cols-4 items-center">
                <span className="font-extrabold">Title: </span>
                <input
                  onChange={(e) =>
                    setTestSeriesNew((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                  className="col-span-3 outline-none border border-1 border-zinc-300 py-2 px-4 rounded-lg"
                  value={testSeriesNew.title || '-'}
                />
              </pre>
              <pre className="w-full grid grid-cols-4">
                <span className="font-extrabold">Description: </span>
                <textarea
                  onChange={(e) =>
                    setTestSeriesNew((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  className="h-[15vh] col-span-3 outline-none border border-1 border-zinc-300 py-2 px-4 rounded-lg"
                  value={testSeriesNew.description || '-'}
                />
              </pre>
              {testSeriesType && (
                <pre className="w-full grid grid-cols-4 items-center">
                  <span className="font-extrabold">Test Series Type: </span>
                  <select
                    onChange={(e) => {
                      const selectedTestSeriesTypeId = parseInt(e.target.value);
                      const selectedTestSeriesType = Object.values(
                        testSeriesType
                      ).find(
                        (testSeriesType) =>
                          testSeriesType?.id === selectedTestSeriesTypeId
                      );
                      setTestSeriesNew((prev) => ({
                        ...prev,
                        test_series_type: selectedTestSeriesType || {},
                      }));
                    }}
                    className="col-span-3 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg"
                  >
                    {Object.entries(testSeriesType).map(
                      ([key, testSeriesType]) =>
                        testSeriesType && testSeriesType?.id ? (
                          <option
                            className="w-full"
                            key={key}
                            value={testSeriesType?.id}
                            selected={
                              testSeriesType?.id ===
                              testSeriesNew.test_series_type.id
                            }
                          >
                            {testSeriesType.title}
                          </option>
                        ) : null
                    )}
                  </select>
                </pre>
              )}
              {courseType && (
                <pre className="w-full grid grid-cols-4 items-center">
                  <span className="font-extrabold">Course Type: </span>
                  <select
                    onChange={(e) => {
                      setCourseTypeId(parseInt(e.target.value));
                      const selectedCourseId = parseInt(e.target.value);
                      const selectedCourse = courseType.find(
                        (course) => course?.id === selectedCourseId
                      );
                      setTestSeriesNew((prev) => ({
                        ...prev,
                        course_type: selectedCourse || {},
                      }));
                    }}
                    className="col-span-3 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg"
                  >
                    {Object.entries(courseType).map(([key, course]) =>
                      course && course?.id ? (
                        <option
                          className="w-full"
                          key={key}
                          value={course?.id}
                          selected={course?.id === testSeries.course_type?.id}
                        >
                          {course.title}
                        </option>
                      ) : null
                    )}
                  </select>
                </pre>
              )}
              <pre className="w-full grid grid-cols-4">
                <span className="font-extrabold">Attempts: </span>
                <div className="flex flex-col col-span-3">
                  <div className="flex flex-col">
                    <select
                      className="p-2 border border-gray-400 rounded-lg bg-gray-200"
                      value={
                        selectedAttempts.length > 0 ? selectedAttempts[0] : ''
                      }
                      onChange={(e) => {
                        const selectedId = Number(e.target.value); // Get the selected attempt ID
                        setSelectedAttempts([selectedId]); // Set the selected attempt ID as the only value
                      }}
                    >
                      <option value="" disabled>
                        Select an attempt
                      </option>
                      {testSeriesNew.course_type?.attempts &&
                        testSeriesNew.course_type.attempts.map((attempt) => (
                          <option key={attempt.id} value={attempt.id}>
                            {attempt.id} - {attempt.month}, {attempt.year}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </pre>

              <div className="w-full items-center">
                <pre className="w-full grid grid-cols-3 items-center">
                  <span className="font-extrabold">Valid From: </span>
                  <input
                    type="date"
                    onChange={(e) => {
                      const dateValue = e.target.value;
                      if (
                        new Date(dateValue) > new Date(testSeriesNew.valid_till)
                      ) {
                        // Optionally, handle invalid date selection
                      }
                      setTestSeriesNew((prev) => ({
                        ...prev,
                        valid_from: dateValue,
                      }));
                    }}
                    className="p-2 outline-none border border-1 border-zinc-300 rounded-lg"
                    value={formatDateForInput(testSeriesNew.valid_from)}
                    max={testSeriesNew.valid_till}
                  />
                </pre>
              </div>

              <div className="w-full items-end">
                <pre className="w-full grid grid-cols-3 items-center">
                  <span className="font-extrabold">Valid Till: </span>
                  <input
                    type="date"
                    onChange={(e) => {
                      const dateValue = e.target.value;
                      if (
                        new Date(dateValue) < new Date(testSeriesNew.valid_from)
                      ) {
                        // Optionally, handle invalid date selection
                      }
                      setTestSeriesNew((prev) => ({
                        ...prev,
                        valid_till: dateValue,
                      }));
                    }}
                    className="p-2 outline-none border border-1 border-zinc-300 rounded-lg"
                    value={formatDateForInput(testSeriesNew.valid_till)}
                    min={testSeriesNew.valid_from}
                  />
                </pre>
              </div>
              <pre className="w-full grid grid-cols-4 items-center">
                <span className="font-extrabold">Status: </span>
                <select
                  onChange={(e) =>
                    setTestSeriesNew((prev) => ({
                      ...prev,
                      status: e.target.value === 'active',
                    }))
                  }
                  className="col-span-3 outline-none border border-1 border-zinc-300 p-2 rounded-lg"
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </pre>

              <pre className="w-full grid grid-cols-3 items-center">
                <span className="font-extrabold">Fees Structure:</span>
                <div className="col-span-2 w-3/4">
                  <div className="flex flex-col gap-3">
                    <input
                      type="file"
                      accept="application/pdf"
                      className="hidden"
                      id="feesStructure"
                      onChange={handleFileChange}
                    />
                    <label
                      htmlFor="feesStructure"
                      className="p-2 px-4 cursor-pointer text-white bg-green-600 hover:bg-green-500 rounded-lg block text-center"
                    >
                      Replace PDF
                    </label>
                    {isFeesUploading && (
                      <div className="w-full bg-gray-200 rounded-lg h-2">
                        <div
                          className="bg-blue-500 h-2 rounded-lg"
                          style={{ width: `${feesUploadProgress}%` }} // Use correct progress state
                        ></div>
                      </div>
                    )}
                  </div>
                  {selectedFile ? (
                    <p className="mt-2 text-center">{selectedFile.name}</p>
                  ) : testSeriesNew.fees_structure ? (
                    <p className="mt-2 text-center">
                      <a
                        href={testSeriesNew.fees_structure}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline cursor-pointer"
                      >
                        {testSeriesNew.fees_structure.substring(76)}{' '}
                      </a>
                    </p>
                  ) : null}
                </div>
              </pre>

              <pre className="w-full grid grid-cols-3 items-center">
                <span className="font-extrabold">Syllabus:</span>
                <div className="col-span-2 w-3/4">
                  <div className="flex flex-col gap-3">
                    <input
                      type="file"
                      accept="application/pdf"
                      className="hidden"
                      id="syllabus"
                      onChange={handleSyllabusChange}
                    />
                    <label
                      htmlFor="syllabus"
                      className="p-2 px-4 cursor-pointer text-white bg-green-600 hover:bg-green-500 rounded-lg block text-center"
                    >
                      Replace PDF
                    </label>
                    {isSyllabusUploading && (
                      <div className="w-full bg-gray-200 rounded-lg h-2">
                        <div
                          className="bg-blue-500 h-2 rounded-lg"
                          style={{ width: `${syllabusUploadProgress}%` }} // Use correct progress state
                        ></div>
                      </div>
                    )}
                  </div>
                  {selectedSyllabus ? (
                    <p className="mt-2 text-center truncate">
                      {selectedSyllabus.name}
                    </p>
                  ) : testSeriesNew.syllabus ? (
                    <p className="mt-2 text-center truncate">
                      <a
                        href={testSeriesNew.syllabus}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline cursor-pointer block w-full"
                      >
                        {testSeriesNew.syllabus.substring(70)}
                      </a>
                    </p>
                  ) : null}
                </div>
              </pre>

              <div className="flex justify-between w-full mt-4">
                {/* <button
                  onClick={deleteTestSeries}
                  className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
                >
                  Delete Test Series
                </button> */}
                <button
                  onClick={updateTestSeries}
                  className="bg-blue-500 mx-auto text-white py-2 px-4 rounded-lg hover:bg-blue-600"
                >
                  Update Test Series
                </button>
              </div>
            </div>
            <div className="bg-gray-200 rounded-lg p-4 mt-4">
              <h2 className="font-bold text-lg">Test Series Details</h2>
              <div className="mt-2">
                <p>
                  <strong>Title:</strong> {testSeries.title}
                </p>
                <p>
                  <strong>Description:</strong> {testSeries.description}
                </p>
                <p>
                  <strong>Course Type:</strong> {testSeries.course_type?.title}
                </p>
                <p>
                  <strong>Test Series Type:</strong>{' '}
                  {testSeries.test_series_type?.title}
                </p>
                <p>
                  <strong>Selected Attempt:</strong>{' '}
                  {selectedAttempts.length > 0 &&
                  testSeries.course_type?.attempts
                    ? testSeries.course_type.attempts
                        .filter((attempt) => attempt.id === selectedAttempts[0])
                        .map(
                          (attempt) =>
                            `${attempt.id} - ${attempt.month}, ${attempt.year}`
                        )
                        .join(', ')
                    : 'None'}
                </p>

                <p>
                  <strong>Valid From:</strong> {testSeries.valid_from}
                </p>
                <p>
                  <strong>Valid Till:</strong> {testSeries.valid_till}
                </p>
                <p>
                  <strong>Status: </strong>
                  {testSeries.status === true ? 'Active' : 'Inactive'}
                </p>
                <p>
                  <strong>Fees Structure: </strong>
                  <a
                    href={testSeriesNew?.fees_structure}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline cursor-pointer"
                  >
                    {testSeriesNew?.fees_structure?.substring(76)}{' '}
                  </a>
                  <p>
                    <strong>Syllabus: </strong>
                    <a
                      href={testSeriesNew?.syllabus}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline cursor-pointer"
                    >
                      {testSeriesNew?.syllabus?.substring(70)}{' '}
                    </a>
                  </p>
                </p>
              </div>
            </div>
          </main>
        ) : (
          <div className="p-16 text-3xl font-extrabold">
            No User Found With Id : {id}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default SpecificTestSeries;
