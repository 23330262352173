import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import TransactionsRow from './TransactionsRow';


const TransactionsTable: React.FC = () => {
    const transactions = useAppSelector((state) => state.transactions.results) || []
    return (
        <section>
            <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
                <div className="grid grid-cols-3 text-left w-full text-base font-bold leading-6 text-blue-600">
                    <p className='p-4'>Payment ID</p>
                    <p className='p-4'>Payment Status</p>
                    <p className='p-4'>Order Value</p>
                </div>
                {transactions.map((transaction, index) => (
                    <TransactionsRow key={index} transactions={transaction} isEven={index % 2 === 0} />
                ))}
            </div>
        </section>
    );
};

export default TransactionsTable;