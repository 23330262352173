import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function AddFees() {

    interface attemptInterface {
        id: number,
        month?: string,
        year?: string
    }

    interface courseTypeInterface {
        id?: number,
        title?: string,
        attempts?: Array<attemptInterface>,
    }

    interface testSeriesTypeInterface {
        id?: number,
        title?: string,
    }

    interface feesType {
        id: number;
        attempts: attemptInterface[];
        course_type: courseTypeInterface;
        test_series_type: testSeriesTypeInterface;
        fees_structure: string;
        syllabus: string;
    }
    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const [feesNew, setFeesNew] = useState<feesType>({
        id : 0,
        attempts : [],
        course_type : {},
        test_series_type : {},
        fees_structure : "",
        syllabus: ""
    })
    const [courseType, setCourseType] = useState<courseTypeInterface[]>([])
    const [courseTypeId, setCourseTypeId] = useState<number>()
    const [testSeriesType, setTestSeriesType] = useState<courseTypeInterface[]>([])
    const [testSeriesTypeId, setTestSeriesTypeId] = useState<number>()
    const [isListVisible, setIsListVisible] = useState(false);
    const [orgAttempts, setOrgAttempts] = useState<number[]>([]);
    const [selectedAttempts, setSelectedAttempts] = useState<number[]>([]);
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const [selectedFile, setSelectedFile] = useState<File>()
    const [selectedSyllabus, setSelectedSyllabus] = useState<File>()

    const toggleListVisibility = () => {
        setIsListVisible(!isListVisible);
    };

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/non-paginated-course-type/`, config)
                .then((res) => {
                    setCourseType(res.data)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    console.log(err)
                })
            BaseUrl.get(`admin/non-paginated-test-series-type/`, config)
                .then((res) => {
                    setTestSeriesType(res.data)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    console.log(err)
                })
        }
    }, [])

    const handleCheckboxChange = (event: { target: { checked: any; }; }, attemptId: number) => {
        if (event.target.checked) {
            setSelectedAttempts([...selectedAttempts, attemptId]);
        } else {
            setSelectedAttempts(selectedAttempts.filter(id => id !== attemptId));
        }
    };

    const removeSelectedAttempt = (attemptId: number) => {
        setSelectedAttempts(selectedAttempts.filter(id => id !== attemptId));
    };

    async function updateSubmission() {
        const config = {
            headers: {
                Authorization: `${token}`,
                'Content-Type': 'application/json',
            },
        };
        let feesStructure = ""
        if (selectedFile) {
            const response = await BaseUrl.get(`fees-structure-presigned-url/?object_key=${selectedFile.name}`, config)
            const presignedUrl = response.data.url
            const uploadResponse = await axios.put(presignedUrl, selectedFile, {
                headers: {
                    'Content-Type': selectedFile.type,
                    'x-amz-acl': 'public-read',
                },
            });
            if (uploadResponse.status === 200) {
                const endIndex = presignedUrl.indexOf('?');
                const extractedString = presignedUrl.substring(61, endIndex);
                const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN
                feesStructure = baseUrl + extractedString
            } else {
                alert("File upload failed");
            }
        }
        let syllabus = ""
        if (selectedSyllabus) {
            const response = await BaseUrl.get(`syllabus-presigned-url/?object_key=${selectedSyllabus.name}`, config)
            const presignedUrl = response.data.url
            const uploadResponse = await axios.put(presignedUrl, selectedSyllabus, {
                headers: {
                    'Content-Type': selectedSyllabus.type,
                    'x-amz-acl': 'public-read',
                },
            });
            if (uploadResponse.status === 200) {
                const endIndex = presignedUrl.indexOf('?');
                const extractedString = presignedUrl.substring(61, endIndex);
                const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN
                syllabus = baseUrl + extractedString
            } else {
                alert("File upload failed");
            }
        }
        BaseUrl.post(`admin/fee-structure-syllabus/`, {
            attempts_ids: selectedAttempts,
            course_type_id: feesNew.course_type.id,
            test_series_type_id: feesNew.test_series_type.id || testSeriesType[0].id,
            fees_structure : feesStructure || feesNew.fees_structure,
            syllabus : syllabus || feesNew.syllabus
        }, config)
            .then((res) => {
                toast.success("Created Succesfully")
                navHandler(`/fees-and-syllabus/${res.data.id}`)
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
        setIsUpdating(false)
    }

    useEffect(() => {
        const attemptsIds = feesNew?.course_type?.attempts?.map(att => att?.id);
        setSelectedAttempts(attemptsIds || []);
    }, [feesNew.course_type.attempts]);

    const handleAttachClick = () => {
        const inpElement = document.getElementById("feesStructure")
        inpElement?.click()
    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(file)
    };

    const handleSyllabusClick = () => {
        const inpElement = document.getElementById("syllabus")
        inpElement?.click()
    };

    const handleSyllabusChange = (event: any) => {
        const file = event.target.files[0];
        setSelectedSyllabus(file)
    };

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Fees structure and syllabuss' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Fees structure and syllabuss
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <a className="text-blue-600">Test Series</a>
                            <span>›</span>
                            <span>Fees structure and syllabuss</span>
                            {/* {status ? <span>{order.user.phone}_{order.created_at}</span> : <span>Not Found</span>} */}
                        </nav>
                    </div>
                </header>
                <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-11/12 mx-auto">
                        {testSeriesType && <pre className='w-full text-sm grid grid-cols-5 items-center'>
                            <span className='font-extrabold'>Test Series type : </span>
                            <select onChange={(e) => {
                                setTestSeriesTypeId(parseInt(e.target.value));
                                const selectedTestSeriesTypeId = parseInt(e.target.value)
                                const selectedTestSeriesType = Object.values(testSeriesType).find(testSeriesType => testSeriesType?.id == selectedTestSeriesTypeId);
                                setFeesNew(prev => ({ ...prev, test_series_type: selectedTestSeriesType || {} }))
                            }} className='col-span-4 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {Object.entries(testSeriesType).map(([key, testSeriesType]) => (
                                    testSeriesType && testSeriesType?.id && (
                                        <option className='w-full' key={key} value={testSeriesType?.id} selected={testSeriesType?.id == feesNew.test_series_type?.id}>
                                            {testSeriesType?.id} - {testSeriesType.title}
                                        </option>
                                    )
                                ))}
                            </select>
                        </pre>}
                        {courseType && <pre className='w-full text-sm grid grid-cols-5 items-center'>
                            <span className='font-extrabold'>Course type : </span>
                            <select onChange={(e) => {
                                setCourseTypeId(parseInt(e.target.value));
                                const selectedCourseId = parseInt(e.target.value)
                                const selectedCourse = Object.values(courseType).find(course => course?.id == selectedCourseId);
                                setFeesNew(prev => ({ ...prev, course_type: selectedCourse || {} }))
                            }} className='col-span-4 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {Object.entries(courseType).map(([key, course]) => (
                                    course && course?.id && (
                                        <option className='w-full' key={key} value={course?.id} selected={course?.id == feesNew.course_type?.id}>
                                            {course?.id} - {course.title}
                                        </option>
                                    )
                                ))}
                            </select>
                        </pre>}
                        <pre className='w-full text-sm grid grid-cols-5'>
                            <span className='font-extrabold'>Attempts : </span>
                            <div className='grid grid-cols-2 gap-2 w-fit col-span-4 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {feesNew?.course_type.attempts && feesNew?.course_type.attempts
                                    .filter(attempt => selectedAttempts.includes(attempt?.id))
                                    .map((attempt) => (
                                        <div key={attempt?.id} className='mb-2 flex text-xs bg-gray-200 w-fit p-2 border border-1 border-gray-400 rounded-lg'>
                                            <div
                                                className='pr-2 border-r-2 border-gray-400 mr-2 cursor-pointer'
                                                onClick={() => removeSelectedAttempt(attempt?.id)}
                                            >
                                                x
                                            </div>
                                            {attempt?.id} - {attempt.month}, {attempt.year}
                                        </div>
                                    ))}
                            </div>
                            <div className='grid grid-cols-12 my-2 p-2 col-start-2 col-span-2'>
                                {!isListVisible ? <img onClick={toggleListVisibility} className='cursor-pointer' src='/add.svg' alt='add' /> : <img onClick={toggleListVisibility} className='cursor-pointer w-1/2' src='/cross.svg' alt='add' />}
                                {isListVisible && (
                                    <div className='col-span-11 mx-auto p-4 border border-2 border-gray-500 rounded-lg'>
                                        {(feesNew.course_type?.attempts && feesNew.course_type?.attempts) && feesNew.course_type?.attempts.map(attempt => (
                                            <div key={attempt?.id}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        value={attempt?.id}
                                                        onChange={(e) => handleCheckboxChange(e, attempt?.id)}
                                                        className='mr-4'
                                                        checked={selectedAttempts.includes(attempt?.id)}
                                                    />
                                                    {attempt?.id} - {attempt.month}, {attempt.year}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Fees Structure : </span>
                            <div className='col-span-2 space-y-2 w-3/4 p-8 border border-dashed border-2 border-gray-400 rounded-lg bg-gray-100'>
                                <img className='mx-auto font-semibold text-sm' src='/upload.svg' alt='upload' />
                                <div className='flex justify-center'>
                                    <input type='file' accept="application/pdf"
                                        className='hidden'
                                        id='feesStructure'
                                        onChange={handleFileChange} />
                                    {(feesNew.fees_structure || selectedFile) ? <button onClick={handleAttachClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Replace</button> : <button onClick={handleAttachClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Browse</button>}
                                </div>
                                <p className='overflow-auto text-center'>{selectedFile?.name || (feesNew.fees_structure?.substring(76))}</p>
                            </div>
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Syllabus : </span>
                            <div className='col-span-2 space-y-2 w-3/4 p-8 border border-dashed border-2 border-gray-400 rounded-lg bg-gray-100'>
                                <img className='mx-auto font-semibold text-sm' src='/upload.svg' alt='upload' />
                                <div className='flex justify-center'>
                                    <input type='file' accept="application/pdf"
                                        className='hidden'
                                        id='syllabus'
                                        onChange={handleSyllabusChange} />
                                    {(feesNew.fees_structure || selectedSyllabus) ? <button onClick={handleSyllabusClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Replace</button> : <button onClick={handleSyllabusClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Browse</button>}
                                </div>
                                <p className='overflow-auto text-center'>{selectedSyllabus?.name || (feesNew.syllabus?.substring(70))}</p>
                            </div>
                        </pre>
                        {(selectedFile && selectedSyllabus && feesNew.test_series_type.id && feesNew.course_type.id) && (isUpdating ?  <button className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white opacity-50'>Creating</button> : <button onClick={()=>{setIsUpdating(true);updateSubmission()}} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white'>Create</button>)}
                    </div>
                    <ToastContainer />
                </main>
            </div>
        </div>
    );
}

export default AddFees;
