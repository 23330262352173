import React from 'react';
import { useNavigate } from 'react-router-dom';

interface TestTypes {
    id: string,
    title : string
}

interface TestSeriesTypeRowProps {
    testTypes: TestTypes;
    isEven: boolean;
}

const TestSeriesTypeRow: React.FC<TestSeriesTypeRowProps> = ({ testTypes, isEven }) => {
    const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
    const navHandler = useNavigate()

    return (
        <div onClick={()=>(navHandler(`/test-series-type/${testTypes.id}`))} className={`grid grid-cols-3 gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto`}>
            <div className="justify-center items-start p-4 text-base font-bold leading-6 text-blue-600 whitespace-nowrap border-t border-solid border-zinc-200 max-md:pr-5">
                {testTypes.id} - {testTypes.title}
            </div>
        </div>
    );
};

export default TestSeriesTypeRow;