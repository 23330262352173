import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateEmployee() {

    interface employeeType {
        password: string;
        phone_number: string;
        first_name: string;
        last_name: string;
        role: string;
    }
    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const [newEmployee, setNewEmployee] = useState<employeeType>({
        password: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        role: "teacher",
    })
    const roles = ["admin","teacher","doubt_solver"]
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/employee/`, config)
                .then((res) => {
                    
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    console.log(err)
                })
        }
    }, [])

    const validatePhoneNumber = (value: string) => {
        const phonePattern = /^[0-9]{10}$/; // Matches a 10-digit number
        return phonePattern.test(value);
    };    

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewEmployee(prev => ({ ...prev, phone_number: value }));
    
        if (validatePhoneNumber(value)) {
            setError('');
        } else {
            setError('Please enter a valid 10-digit phone number');
        }
    };

    
    function updateEmployee() {
        if (error) {
            toast.error('Please fix the errors before updating');
            return;
        }
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        BaseUrl.post(`admin/employee/`, {
            phone_number: newEmployee.phone_number,
            first_name: newEmployee.first_name,
            last_name: newEmployee.last_name,
            role: newEmployee.role,
            password: newEmployee.password
        }, config)
            .then((res) => {
                navHandler(`/employee/${res.data.id}`)
                toast.success("Updated Succesfully")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
    }

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Employees' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Employees
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <a href="/users" className="text-blue-600">Employees</a>
                            <span>›</span>
                            <span className='text-blue-600 cursor-pointer' onClick={() => navHandler("/employees")}>Employee</span>
                            <span>›</span>
                            <span>Create</span>
                        </nav>
                    </div>
                </header>
                <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-4/5 mx-auto">
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Phone : </span>
                            <input type='number' onChange={handlePhoneChange} className='col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={newEmployee.phone_number} />
                        </pre>
                        {error && <p className="col-span-2 text-red-500">{error}</p>}
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>First Name : </span>
                            <input onChange={(e)=>setNewEmployee(prev => ({...prev,first_name:e.target.value}))} className='col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={newEmployee.first_name} />
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Last Name : </span>
                            <input onChange={(e)=>setNewEmployee(prev => ({...prev,last_name:e.target.value}))} className='col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={newEmployee.last_name} />
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Password : </span>
                            <input type='password' onChange={(e)=>setNewEmployee(prev => ({...prev,password:e.target.value}))} className='col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={newEmployee.password} />
                        </pre>
                        <div>
                            <pre className='w-full grid grid-cols-3 items-center'>
                                <span className='font-extrabold'>Confirm Password : </span>
                                <input type='password' onChange={(e) => setConfirmPassword(e.target.value)} className='col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={confirmPassword} />
                            </pre>
                            <pre className='w-full grid grid-cols-3 items-center'>
                                {(confirmPassword && (confirmPassword != newEmployee.password)) && <p className='col-start-2 mt-2 text-red-400 text-sm font-semibold'>Password doesn't match</p>}
                            </pre>
                        </div>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Role : </span>
                            <select
                            onChange={(e)=>{
                                setNewEmployee((prev)=>({...prev,role:e.target.value}))
                            }} className='col-span-2 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {roles.map((role, index) => (
                                    role && (
                                        <option className='w-full' key={index} value={role} selected={role == newEmployee.role}>
                                            {role}
                                        </option>
                                    )
                                ))}
                            </select>
                        </pre>
                        {(newEmployee.first_name && newEmployee.phone_number && newEmployee.role && newEmployee.password && (newEmployee.password == confirmPassword)) && <button onClick={updateEmployee} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white'>Create</button>}
                    </div>
                </main>
            </div>
            <ToastContainer />
        </div>
    );
}

export default CreateEmployee;
