import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import TestRow from './TestRow';


const TestTable: React.FC = () => {
    const tests = useAppSelector((state) => state.test.results) || []
    return (
        <section>
            <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
                <div className="grid grid-cols-12 text-left w-full text-base font-bold leading-6 text-blue-600">
                    <p className='p-4'>S.No.</p>
                    <p className='p-4 col-span-2'>Title</p>
                    <p className='p-4'>Year</p>
                    <p className='p-4'>Course</p>
                    <p className='p-4 col-span-2'>Subject</p>
                    <p className='p-4'>Test Type</p>
                    <p className='p-4'>Valid From</p>
                    <p className='p-4'>Valid To</p>
                    <p className='p-4'>Time Duration</p>
                    <p className='p-4'>Marks</p>
                </div>
                {tests.map((test, index) => (
                    <TestRow key={index} test={test} isEven={index % 2 === 0} />
                ))}
            </div>
        </section>
    );
};

export default TestTable;