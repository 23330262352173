import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TestSeriesState {
    count: number;
    previous: string | null;
    next: string | null;
    current: number;
    results: any[]; 
}

const initialState: TestSeriesState = {
    count: 0,
    previous: null,
    next: null,
    results: [],
    current: 1
};

export const testSeriesSlice = createSlice({
    name: 'testSeries', 
    initialState,
    reducers: {
        setTestSeries: (state, action: PayloadAction<TestSeriesState>) => {
            state.count = action.payload.count;
            state.previous = action.payload.previous;
            state.next = action.payload.next;
            state.results = action.payload.results;
            state.current = action.payload.current || 1;
        },
        setCurrentTestSeriesPage: (state, action: PayloadAction<number>) => {
            state.current = action.payload;
        },
    },
});

export const { setTestSeries, setCurrentTestSeriesPage } = testSeriesSlice.actions;

export default testSeriesSlice.reducer;