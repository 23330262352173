import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function SpecificSubmission() {

    interface Teacher {
        id: number,
        first_name: string,
        last_name: string,
        role: string
    }

    interface Submission {
        id: number;
        test: {
            test_title: string,
            subject: {
                id: number
            },
            answer_key : string,
            question_paper : string
        },
        user: {
            phone: number
        }
        assigned_teacher: Teacher;
        check_status: boolean;
        checked_answer_sheet: string;
        submitted_answer_sheet: string;
        remarks: string
    }

    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const { id } = useParams();
    const [status, setStatus] = useState<boolean>(true)
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const [submission, setSubmission] = useState<Submission>({
        id: 0,
        test: {
            test_title: "",
            subject: {
                id: 0
            },
            answer_key : "",
            question_paper : ""
        },
        user: {
            phone: 0
        },
        assigned_teacher: {
            id: 0,
            first_name: "",
            last_name: "",
            role: ""
        },
        check_status: false,
        checked_answer_sheet: "",
        submitted_answer_sheet: "",
        remarks: ""
    })
    const [submissionNew, setSubmissionNew] = useState<Submission>({
        id: 0,
        test: {
            test_title: "",
            subject: {
                id: 0
            },
            answer_key : "",
            question_paper : ""
        },
        user: {
            phone: 0
        },
        assigned_teacher: {
            id: 0,
            first_name: "",
            last_name: "",
            role: ""
        },
        check_status: false,
        checked_answer_sheet: "",
        submitted_answer_sheet: "",
        remarks: ""
    })
    const [checkedAnswerKey, setCheckedAnswerSheet] = useState<File>()
    const [teachers, setTeachers] = useState<Teacher[]>([])
    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/submission/${id}`, config)
                .then((res) => {
                    setSubmission(res.data)
                    setSubmissionNew(res.data)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    if (err.response.status == 404)
                        setStatus(false)
                    console.log(err)
                })
        }
    }, [])

    useEffect(() => {
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };

        BaseUrl.get(`get-teacher-by-subject?object_key=${submission.test.subject.id}`, config)
            .then((res) => {
                setTeachers(res.data.teachers)
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
    }, [submission.test.subject.id])

    async function updateSubmission() {
        const config = {
            headers: {
                Authorization: `${token}`,
                'Content-Type': 'application/json',
            },
        };
        let checkedAnsSheet = ""
        if (checkedAnswerKey) {
            const response = await BaseUrl.get(`submission-presigned-url/?object_key=${checkedAnswerKey.name}`, config)
            const presignedUrl = response.data.url
            const uploadResponse = await axios.put(presignedUrl, checkedAnswerKey, {
                headers: {
                    'Content-Type': checkedAnswerKey.type,
                    'x-amz-acl': 'public-read',
                },
            });
            if (uploadResponse.status === 200) {
                const endIndex = presignedUrl.indexOf('?');
                const extractedString = presignedUrl?.substring(61, endIndex);
                const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN
                checkedAnsSheet = baseUrl + extractedString
            } else {
                alert("File upload failed");
            }
        }
        BaseUrl.patch(`admin/submission/${id}/`, {
            assigned_teacher_id: submissionNew.assigned_teacher?.id,
            check_status: submissionNew.check_status,
            checked_answer_sheet: checkedAnsSheet || submissionNew.checked_answer_sheet,
            remarks: submissionNew.remarks
        }, config)
            .then((res) => {
                setSubmission(res.data)
                setSubmissionNew(res.data)
                setCheckedAnswerSheet(undefined)
                toast.success("Updated Succesfully")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
        setIsUpdating(false)
    }

    const handleAnswerButtonClick = () => {
        const inpElement = document.getElementById("answerFile")
        inpElement?.click()
    };

    const handleCheckedAnswerSheetChange = (event: any) => {
        // Handle the file selection
        const file = event.target.files[0];
        setCheckedAnswerSheet(file)
    };

    async function downloadFile(fileUrl: string) {
        try {
            // Fetch the file from the URL
            const response = await fetch(fileUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            });

            // Convert the response into a Blob
            const blob = await response.blob();

            // Create a link element, set its href to a URL created from the Blob, and trigger the download
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileUrl.split('/').pop() || "downloaded_file"; // Extract the filename from the URL
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Download failed:', error);
        }
    }

    return (
        <div className="text-sm grid grid-cols-5">
            <Sidebar activeLabel='Submissions' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Submissions
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <p className="text-blue-600">Reports</p>
                            <span>›</span>
                            <span className='cursor-pointer text-blue-600' onClick={() => navHandler("/submissions")}>Submissions - </span>
                            {status ? <span>{submission.test.test_title}</span> : <span>Not Found</span>}
                        </nav>
                    </div>
                </header>
                {status ? <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-11/12 mx-auto">
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Test : </span>
                            <p className='col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg'>{submissionNew.test.test_title}</p>
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>User : </span>
                            <p className='col-span-2 w-1/2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg'>{submissionNew.user.phone}</p>
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Assigned Teacher : </span>
                            <select onChange={(e) => {
                                const selectedTeacher = Object.values(teachers).find(teacher => teacher.id == teachers[parseInt(e.target.value)].id) || {
                                    id: 0,
                                    first_name: "",
                                    last_name: "",
                                    role: ""
                                };
                                setSubmissionNew((prev) => ({ ...prev, assigned_teacher: selectedTeacher }))
                            }} className='col-span-2 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg'>
                                {submission.assigned_teacher == null && <option value="null" selected={submission.assigned_teacher == null}> ---- </option>}
                                {teachers.length > 0 && teachers.map((teacher, index) => (
                                    teacher.role == "teacher" && <option value={index} selected={submission?.assigned_teacher?.id == teacher.id}>
                                        {teacher.first_name} {teacher.last_name}
                                    </option>
                                ))}
                            </select>
                        </pre>
                        <pre className='w-full grid grid-cols-3'>
                            <span className='font-extrabold'>Remarks : </span>
                            <textarea onChange={(e) => setSubmissionNew((prev) => ({ ...prev, remarks: e.target.value }))} className='resize-none h-[20vh] col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={submissionNew.remarks} />
                        </pre>
                        <pre className='w-full grid grid-cols-3 justify-left'>
                            <span className='font-extrabold'>Check Status : </span>
                            <div className='flex justify-left'>
                                <input onChange={(e) => setSubmissionNew((prev) => ({ ...prev, check_status: e.target.checked }))} type='checkbox' checked={submissionNew.check_status} />
                            </div>
                        </pre>
                        <pre className='w-full grid grid-cols-3 justify-left'>
                            <span className='font-extrabold'>Documents : </span>
                            <div className='grid grid-cols-3 col-span-2 gap-2'>
                                {submissionNew.test.question_paper && <div className='flex flex-col items-left space-y-2'>
                                    <p className='font-semibold text-center'>Question Paper</p>
                                    <button onClick={()=>downloadFile(submissionNew.test.question_paper)} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg' >Download</button>
                                </div>}
                                {submissionNew.submitted_answer_sheet && <div className='flex flex-col items-left space-y-2'>
                                    <p className='font-semibold text-center'>Answer Sheet</p>
                                    <button onClick={()=>downloadFile(submissionNew.submitted_answer_sheet)} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg' >Download</button>
                                </div>}
                                {submissionNew.test.answer_key && <div className='flex flex-col items-left space-y-2'>
                                    <p className='font-semibold text-center'>Standard Answer Key</p>
                                    <button onClick={()=>downloadFile(submissionNew.test.answer_key)} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg' >Download</button>
                                </div>}
                            </div>
                        </pre>
                        <pre className='w-full grid grid-cols-3 items-center'>
                            <span className='font-extrabold'>Upload checked answer sheet : </span>
                            <div className='col-span-2 space-y-2 w-3/4 p-8 border border-dashed border-2 border-gray-400 rounded-lg bg-gray-100'>
                                <img className='mx-auto font-semibold text-sm' src='/upload.svg' alt='upload' />
                                <p className='text-center font-semibold text-sm'>Upload checked answer sheet : </p>
                                <div className='flex justify-center'>
                                    <input type='file' accept='application/pdf'
                                        className='hidden'
                                        id='answerFile'
                                        onChange={handleCheckedAnswerSheetChange} />
                                    {(submissionNew.checked_answer_sheet || checkedAnswerKey) ? <button onClick={handleAnswerButtonClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Replace</button> : <button onClick={handleAnswerButtonClick} className='p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg'>Browse</button>}
                                </div>
                                <p className='overflow-auto text-center'>{checkedAnswerKey?.name || (submissionNew.checked_answer_sheet)?.substring(73)}</p>
                            </div>
                        </pre>
                        {isUpdating ? (JSON.stringify(submissionNew) != JSON.stringify(submission) || (checkedAnswerKey)) && <button className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white opacity-50'>Updating</button> : (JSON.stringify(submissionNew) != JSON.stringify(submission) || (checkedAnswerKey)) && <button onClick={()=>{setIsUpdating(true);updateSubmission()}} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white'>Update</button>}
                    </div>
                </main> : <div className='p-16 text-3xl font-extrabold'>No User Found With Id : {id}</div>}
            </div>
            <ToastContainer />
        </div>
    );
}

export default SpecificSubmission;