import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Transactions {
    id: string,
    payment_id: string;
    payment_status: string;
    order_value: string;
}

interface TransactionsRowProps {
    transactions: Transactions;
    isEven: boolean;
}

const TransactionsRow: React.FC<TransactionsRowProps> = ({ transactions, isEven }) => {
    const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
    const navHandler = useNavigate()

    return (
        <div onClick={()=>(navHandler(`/transaction/${transactions.id}`))} className={`grid grid-cols-3 gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto`}>
            <div className="justify-center items-start p-4 text-base font-bold leading-6 text-blue-600 whitespace-nowrap border-t border-solid border-zinc-200 max-md:pr-5">
                {transactions.payment_id}
            </div>
            <div className="justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {transactions.payment_status || '-'}
            </div>
            <div className="justify-center items-start p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 max-md:pr-5">
                {transactions.order_value || '-'}
            </div>
        </div>
    );
};

export default TransactionsRow;