import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SpecificTestType() {

    interface testTypeType {
        id: string,
        title: string,
        description: string,
    }
    const navHandler = useNavigate()
    const token = useAppSelector((state: RootState) => state.auth.access)
    const dispatch = useAppDispatch()
    const { id } = useParams();
    const [status, setStatus] = useState<boolean>(true)
    const [testType, setTestSeriesType] = useState<testTypeType>({
        id: "",
        title: "",
        description: ""
    })
    const [title, setTitle] = useState<string>()
    const [description, setDescription] = useState<string[]>([])
    const [errorLines, setErrorLines] = useState<number[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (!token) {
            navHandler("/login")
        }
        else {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            BaseUrl.get(`admin/test-series-type/${id}`, config)
                .then((res) => {
                    setTestSeriesType(res.data)
                })
                .catch((err) => {
                    if (err.response.status == 403)
                        dispatch(setAccess(""))
                    if (err.response.status == 404)
                        setStatus(false)
                    console.log(err)
                })
        }
    }, [])

    function updateDescription(point: string, index: number) {
        setDescription(prevDescription => {
            const updatedDescription = [...prevDescription];
            updatedDescription[index] = point;
            return updatedDescription;
        });
        if (point.includes(',')) {
            setErrorLines(prevErrorLines => {
                if (!prevErrorLines.includes(index)) {
                    return [...prevErrorLines, index];
                }
                return prevErrorLines;
            });
        } else {
            setErrorLines(prevErrorLines => {
                return prevErrorLines.filter(lineIndex => lineIndex !== index);
            });
        }
    }
    function updateTestType() {
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        BaseUrl.patch(`admin/test-series-type/${id}/`, {
            title: title,
            description: description.join(", ")
        }, config)
            .then((res) => {
                setTestSeriesType(res.data)
                setTitle("")
                toast.success("Updated Succesfully")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
    }

    useEffect(()=>{
        const newValue = testType.description;
        const newSentences = newValue.split(',').map(sentence => sentence.trim());
        setDescription(newSentences)
    },[testType.description])

    function deleteTestType() {
        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };
        BaseUrl.delete(`admin/test-series-type/${id}/`, config)
            .then((res) => {
                toast.success("Deleted Succesfully")
                navHandler("/test-series-types")
            })
            .catch((err) => {
                if (err.response.status == 403)
                    dispatch(setAccess(""))
                console.log(err)
            })
        setIsModalOpen(false)
    }

    return (
        <div className="text-sm grid grid-cols-5">
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 max-w-md mx-auto">
                        <h2 className="text-xl font-semibold text-gray-800 mb-6">
                            Are you sure you want to delete this Test Series Type?
                        </h2>
                        <div className="flex justify-end gap-4">
                            <button
                                onClick={deleteTestType}
                                className="px-6 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 transition duration-200"
                            >
                                Yes
                            </button>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="px-6 py-2 bg-gray-300 text-gray-800 font-semibold rounded-md hover:bg-gray-400 transition duration-200"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Sidebar activeLabel='testTypes' />
            <div className='col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]'>
                <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
                        <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                            Test Series Types
                        </h1>
                        <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
                            <a href="/" className="grow text-blue-600">Home</a>
                            <span>›</span>
                            <span className="text-blue-600">Test Series</span>
                            <span>›</span>
                            <a href="/test-series-types" className="text-blue-600">TestSeries Types</a>
                            <span>›</span>
                            {status ? <span>{testType.id} - {testType.title}</span> : <span>Not Found</span>}
                        </nav>
                    </div>
                </header>
                {status ? <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-5/6 mx-auto">
                        <pre className='w-full text-sm grid grid-cols-5 items-center'>
                            <span className='font-extrabold'>Title : </span>
                            <input onChange={(e) => setTitle(e.target.value)} className='col-span-4 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={title || testType.title || '-'} />
                        </pre>
                        <pre className='w-full text-sm grid grid-cols-5'>
                            <span className='font-extrabold'>Description: </span>
                            <ul className='list-disc col-span-4 flex flex-col gap-4 w-full'>
                                <li>
                                    <input maxLength={75} onChange={(e) => updateDescription(e.target.value,0)} className='w-full outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={description[0] || '-'} />
                                    {errorLines.includes(0) && <div className='text-sm text-red-500 p-2'>Input should not contain a comma.</div>}
                                </li>
                                <li>
                                    <input maxLength={75} onChange={(e) => updateDescription(e.target.value,1)} className='w-full outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={description[1] || '-'} />
                                    {errorLines.includes(1) && <div className='text-sm text-red-500 p-2'>Input should not contain a comma.</div>}
                                </li>
                                <li>
                                    <input maxLength={75} onChange={(e) => updateDescription(e.target.value,2)} className='w-full outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={description[2] || '-'} />
                                    {errorLines.includes(2) && <div className='text-sm text-red-500 p-2'>Input should not contain a comma.</div>}
                                </li>
                                <li>
                                    <input maxLength={75} onChange={(e) => updateDescription(e.target.value,3)} className='w-full outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={description[3] || '-'} />
                                    {errorLines.includes(3) && <div className='text-sm text-red-500 p-2'>Input should not contain a comma.</div>}
                                </li>
                                <li>
                                    <input maxLength={75} onChange={(e) => updateDescription(e.target.value,4)} className='w-full outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg' value={description[4] || '-'} />
                                    {errorLines.includes(4) && <div className='text-sm text-red-500 p-2'>Input should not contain a comma.</div>}
                                </li>
                            </ul>
                        </pre>
                    </div>
                    {((title && testType.title != title)||(description.length>0 && description.join(", ")!= testType.description && errorLines.length==0)) && <button onClick={updateTestType} className='p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white w-1/4 mx-auto mt-4'>Update</button>}
                    <button onClick={() => setIsModalOpen(true)} className='p-2 rounded-lg bg-red-600 text-lg font-semibold text-white w-5/6 my-4 w-1/4 mx-auto'>Delete</button>
                </main> : <div className='p-16 text-3xl font-extrabold'>No User Found With Id : {id}</div>}
            </div>
            <ToastContainer />
        </div>
    );
}

export default SpecificTestType;
