import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import UserRow from './UserRow';


const UsersTable: React.FC = () => {
    const users = useAppSelector((state) => state.users.results) || []
    return (
        <section>
            <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
                <div className="grid grid-cols-10 text-left w-full text-base font-bold leading-6 text-blue-600">
                    <p className='p-4'>Id</p>
                    <p className='p-4 col-span-2'>Phone</p>
                    <p className='p-4 col-span-2'>Name</p>
                    <p className='p-4 col-span-2'>Gender</p>
                    <p className='p-4 col-span-3'>Email</p>
                </div>
                {users.map((user, index) => (
                    <UserRow key={index} user={user} isEven={index % 2 === 0} />
                ))}
            </div>
        </section>
    );
};

export default UsersTable;