import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DoubtsState {
    count: number;
    previous: string | null;
    next: string | null;
    current: number;
    results: any[]; 
}

const initialState: DoubtsState = {
    count: 0,
    previous: null,
    next: null,
    results: [],
    current: 1
};

export const DoubtsSlice = createSlice({
    name: 'doubts', 
    initialState,
    reducers: {
        setDoubts: (state, action: PayloadAction<DoubtsState>) => {
            state.count = action.payload.count;
            state.previous = action.payload.previous;
            state.next = action.payload.next;
            state.results = action.payload.results;
            state.current = action.payload.current || 1;
        },
        setCurrentDoubtPage: (state, action: PayloadAction<number>) => {
            state.current = action.payload;
        },
    },
});

export const { setDoubts, setCurrentDoubtPage } = DoubtsSlice.actions;

export default DoubtsSlice.reducer;