import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import SubmissionRow from './SubmissionRow';

interface submissionProp {
    assigned : boolean,
    setTeacherChange : React.Dispatch<React.SetStateAction<boolean>>
    teacherChange : boolean
}

interface Teacher {
    id: number,
    first_name: string,
    last_name: string,
    role: string
}

interface Submission {
    id: number;
    test: {
        test_title: string,
        subject: {
            id: number
        }
    },
    user: {
        phone: number
    }
    assigned_teacher: Teacher;
    check_status: boolean;
}

const SubmissionsTable: React.FC<submissionProp> = ({assigned, teacherChange, setTeacherChange}) => {
    const submissions = useAppSelector((state) => state.submission.results) || []
    return (
        <section>
            <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
                <div className="grid grid-cols-12 text-left w-full text-base font-bold leading-6 text-blue-600">
                    <p className='p-4 col-span-5'>Test</p>
                    <p className='p-4 col-span-2'>User</p>
                    <p className='p-4 col-span-3'>Assigned teacher</p>
                    <p className='p-4 col-span-2'>Check Status</p>
                </div>
                {assigned && submissions.map((submission:Submission, index) => (
                    submission.assigned_teacher && <SubmissionRow teacherChange={teacherChange} setTeacherChange={setTeacherChange} key={index} submission={submission} isEven={index % 2 === 0} />
                ))}
                {!assigned && submissions.map((submission:Submission, index) => (
                    !(submission.assigned_teacher) && <SubmissionRow teacherChange={teacherChange} setTeacherChange={setTeacherChange} key={index} submission={submission} isEven={index % 2 === 0} />
                ))}
            </div>
        </section>
    );
};

export default SubmissionsTable;