import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TestState {
    count: number;
    previous: string | null;
    next: string | null;
    current: number;
    results: any[]; 
}

const initialState: TestState = {
    count: 0,
    previous: null,
    next: null,
    results: [],
    current: 1
};

export const testSlice = createSlice({
    name: 'test', 
    initialState,
    reducers: {
        setTest: (state, action: PayloadAction<TestState>) => {
            state.count = action.payload.count;
            state.previous = action.payload.previous;
            state.next = action.payload.next;
            state.results = action.payload.results;
            state.current = action.payload.current || 1;
        },
        setCurrentTestPage: (state, action: PayloadAction<number>) => {
            state.current = action.payload;
        },
    },
});

export const { setTest, setCurrentTestPage } = testSlice.actions;

export default testSlice.reducer;